<script>
	import { _ } from 'svelte-i18n';
	import { onDestroy, onMount } from 'svelte';
	import { data, showSpinner, notification, isRemoteAuthClient } from '../../stores.js';
	import { deleteConsent, getConsent } from '../../utils/api-calls.js';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import {
		handleConsentResponse,
		getDeviceTheme,
		generateQR,
		clearSessionStorage,
		getRecommendedProviders,
		promptForPasskey
	} from '../../utils/helper.js';
	import { logPlausibleEvent } from '../../utils/helper.js';
	import Login from '../../lib/Login.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import FullPageModal from '../../lib/modal/FullPageModal.svelte';

	const provider_hints = {
		shown: [],
		hidden: []
	};

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;

	let evtSource;
	let showQRModal = false;
	let qrLink = '';
	let qrLinkCopied = false;
	let qrLinkScanned = false;
	let qrSvg = null;
	let providerHints;

	let showPasskeyLogin = false;

	//on logout, get original query params from session storage to make get consent call
	const authorizeQueryParams = sessionStorage.getItem('authorize_query_params');

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent(authorizeQueryParams || '');
			} catch (err) {
				console.error(err);
				//show error state at root page
				return replaceRoute('/');
			}
		}

		if ($data.isUserLoggedIn) {
			await logPlausibleEvent({ u: '/login/auto' });
			return replaceRoute('/');
		}

		//update UI with provider_hints query param
		useProviderHints();

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				console.log('keep-alive: ' + event.data);
			});
		}

		showPasskeyLogin = await promptForPasskey();

		logUserReleaseFunnelPlausibleEvent();
		clearSessionStorage(['app', 'authorize_query_params', 'az_release_funnel']);
		logPlausibleEvent({ u: '/login' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	function logUserReleaseFunnelPlausibleEvent() {
		//Start of New User Release Funnel
		//event not already sent + no Hellō cookie
		if (!sessionStorage.az_release_funnel && !$data.isUserLoggedIn && !$data.preferred) {
			const allRecommendedProviders = getRecommendedProviders(
				provider_hints.shown,
				provider_hints.hidden
			);
			if (showPasskeyLogin) {
				allRecommendedProviders.push('passkey');
			}
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				console.error(err);
			}
			logPlausibleEvent({
				n: 'AZ Request',
				p: { client_id, recommended_providers: allRecommendedProviders.sort().join(' '), redirect },
				u: '/login'
			});
			sessionStorage.setItem('az_release_funnel', 'az_request');
		}
	}

	function useProviderHints() {
		const searchParams = new URLSearchParams(authorizeQueryParams);
		providerHints =
			searchParams.get('provider_hint') ||
			searchParams.get('provider_hints') ||
			$data.provider_hint ||
			$data.provider_hints;
		if (providerHints) {
			const possibleSlugs = [
				...logins.filter((i) => !i.no_login).map((i) => i.slug),
				'email',
				'ethereum',
				'qrcode',
				'passkey'
			];
			let unknownSlugs = [];
			for (let provider of providerHints.split(' ')) {
				if (provider && provider.endsWith('--')) {
					provider = provider.substring(0, provider.length - 2);

					if (!possibleSlugs.includes(provider)) {
						unknownSlugs.push(provider);
						continue;
					}

					provider_hints.hidden = [...provider_hints.hidden, provider];
					continue;
				}

				if (!possibleSlugs.includes(provider)) {
					unknownSlugs.push(provider);
					continue;
				}

				provider_hints.shown = [...provider_hints.shown, provider];
			}

			if (unknownSlugs.length) {
				console.warn(
					`Unsupported provider_hint values: ${unknownSlugs.join(
						', '
					)}\nPlease see https://www.hello.dev/documentation/provider-hint.html#possible-slug-values for supported values.`
				);
			}
		}
	}

	async function contactLogin({ email } = {}) {
		try {
			const res = await getConsent();
			$data = res;

			//AZ Funnel
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				console.error(err);
			}

			//New User Release Funnel
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
			//session funnel state is valid and not already sent
			if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
				//existing user: not auto-flow + isNewUser + not rerelease
				if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
					await logPlausibleEvent({
						n: 'AZ Login Success',
						p: { client_id, provider: email ? 'email' : 'phone', redirect },
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_success');
				} else {
					await logPlausibleEvent({
						n: 'AZ Existing User',
						p: { client_id, provider: email ? 'email' : 'phone', redirect },
						u: '/login'
					});
					sessionStorage.removeItem('az_release_funnel');
				}
			}

			if (res.uri && res.response_mode) {
				$showSpinner = true;
				handleConsentResponse(res);
				return res;
			}
		} catch (err) {
			console.error(err);
			const json = await err.json();
			if (err.status === 403) {
				if (json?.error?.message === 'ACCESS_DENIED') {
					$notification = {
						text: 'User does not have access to Dev Redirect URIs',
						type: 'error'
					};
				}
			}
		}
	}

	let continueWithQRCodeAjax = false;
	function continueWithQRCode() {
		continueWithQRCodeAjax = true;
		const searchParams = new URLSearchParams();
		searchParams.set('prefers-color-scheme', getDeviceTheme());
		searchParams.set('language', window.navigator.language);
		if (providerHints) {
			const filteredProviderHints = providerHints
				//does not make sense to propagate the qrcode hint to remote auth client where its going to be hidden anyway
				.replaceAll('qrcode', '')
				.replaceAll('  ', ' ')
				.trim();
			if (filteredProviderHints) {
				searchParams.set('provider_hint', filteredProviderHints);
			}
		}
		evtSource = new EventSource('/api/v1/login/qrcode?' + searchParams.toString());
		evtSource.addEventListener('qr_code', async ({ data }) => {
			qrLink = data;
			qrSvg = await generateQR(data);
			continueWithQRCodeAjax = false;
			showQRModal = true;
		});
		evtSource.addEventListener('scanned', () => {
			evtSource.close();
			navigateTo('/remote');
		});
		evtSource.addEventListener('keep-alive', (event) => {
			console.log('keep-alive: ' + event.data);
		});
	}
	async function copyQRLink() {
		qrLinkCopied = true;
		await navigator.clipboard.writeText(qrLink);
		setTimeout(() => {
			qrLinkCopied = false;
		}, 1000);
	}

	async function deleteQRCode() {
		try {
			showQRModal = false;
			qrLink = '';
			await fetch('/api/v1/login/qrcode', {
				method: 'DELETE'
			});
			if (evtSource) {
				evtSource.close();
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}

	let showPasskeyNotFoundErrorModal = false;
</script>

{#if showPasskeyNotFoundErrorModal}
	<FullPageModal on:close={() => (showPasskeyNotFoundErrorModal = false)}>
		<svg class="h-8 w-8 mx-auto" viewBox="0 0 651 634" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M237.333 1.06607C184.933 5.73273 136.533 39.7328 114.667 87.0661C96.7999 125.999 95.9999 169.866 112.667 209.866C121.467 230.933 140 254.933 158.267 269.066C210.133 308.933 279.867 311.466 334.133 275.333C373.2 249.333 397.867 205.866 400.267 158.666C401.867 127.466 394.933 99.7328 378.933 72.6661C370.8 58.7994 349.067 35.7327 335.467 26.6661C318 14.9327 295.867 5.9994 276.4 2.66607C268.267 1.33273 245.333 0.266065 237.333 1.06607Z"
				fill="currentColor"
			/>
			<path
				d="M511.466 186C498.133 189.067 490.133 191.867 479.6 197.467C438.533 219.2 414.933 261.733 417.6 309.067C419.6 345.733 443.066 383.067 475.733 401.333C482.8 405.333 483.333 405.867 483.333 410.4C483.333 413.2 483.6 453.333 483.733 499.6L484 584L509.066 608.933L534 634L575.6 592.267L617.333 550.667L592.4 525.733L567.333 500.667L592.266 475.733L617.333 450.667L596.8 430.133L576.266 409.6L585.2 405.333C603.333 396.667 622 380 632.933 362.667C645.2 343.067 650.133 325.067 650 300.533C650 278.8 647.333 267.2 638.133 248.667C623.6 219.2 598.4 198 566.666 188.667C551.6 184.133 525.2 182.933 511.466 186ZM544.8 236.267C551.6 238.267 560 245.467 563.733 252.533C567.733 260.133 567.733 274.4 563.733 282.133C557.733 293.333 546.8 300 534 300C506.533 300 491.2 269.467 507.733 247.733C516.266 236.4 530.8 232 544.8 236.267Z"
				fill="currentColor"
			/>
			<path
				d="M170.667 352.799C95.9999 364.265 34.3999 416.265 10.7999 487.599C2.53324 512.665 1.73324 519.332 1.06657 570.265L0.399902 617.332H217.2H434V525.999V434.665L418.933 419.599C403.733 404.532 393.333 390.532 386.267 375.732C382.667 368.265 382.533 367.999 372 363.865C359.2 358.799 340.8 353.999 326.8 352.132C310.933 349.865 185.867 350.399 170.667 352.799Z"
				fill="currentColor"
			/>
		</svg>
		<h1 class="text-center mt-3 block">{$_('Hellō Passkey not found')}</h1>
		<p class="mt-2 block opacity-60 text-sm">
			{$_('You need to log in with your preferred provider')}
		</p>
		<button
			on:click={() => (showPasskeyNotFoundErrorModal = false)}
			class="btn-background h-9 mt-6 w-full">{$_('OK')}</button
		>
	</FullPageModal>
{/if}

{#if showQRModal}
	<FullPageModal on:close={deleteQRCode}>
		<div class="flex justify-center">
			<h1 class="text-xl">Scan QR Code</h1>
			<button class="absolute right-5" on:click={deleteQRCode}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="2"
					stroke="currentColor"
					class="w-6 h-6"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</button>
		</div>
		<div class="w-full dark:invert">
			{@html qrSvg}
		</div>
		<button on:click={copyQRLink}
			>{qrLinkCopied ? 'Copied to clipboard!' : 'Copy to clipboard'}</button
		>
	</FullPageModal>
{/if}

<AuthorizeLayout
	heading={$_('Select your preferred login provider')}
	showHeading={!$data.subjects?.length && !$data.preferred?.length && !$data.login_hint?.email}
	showTitleBar={!qrLinkScanned && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	showCancelConsent={false}
	{cancelConsent}
>
	{#if !$showSpinner && $data?.version}
		<Login
			{contactLogin}
			rememberPrompt
			getData={getConsent}
			{provider_hints}
			{continueWithQRCodeAjax}
			on:qrcode={continueWithQRCode}
			{showPasskeyLogin}
			on:passkeyLoginFail={() => (showPasskeyNotFoundErrorModal = true)}
			login_hint={$data.login_hint}
		/>
	{/if}
</AuthorizeLayout>
