<script>
	import { _ } from 'svelte-i18n';
	import { showSpinner, data, notification } from '../../stores';
	import AppInfo from '../AppInfo.svelte';
	import DeviceInfo from '../DeviceInfo.svelte';
	import Notification from '../Notification.svelte';
	import AuthorizeTitleBar from '../AuthorizeTitleBar.svelte';
	import AuthorizeFooter from '../AuthorizeFooter.svelte';

	export let heading = '';
	export let cancelConsent = () => {};

	export let showHeading = true;
	export let showAppInfo = true;
	export let showTitleBar = true;
	export let showDeviceInfo = false;
	export let showCancelConsent = true;

	export let closePageState = false;
</script>

{#if showTitleBar}
	<AuthorizeTitleBar />
{/if}

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner && $data?.version}
	{#if showDeviceInfo}
		<DeviceInfo on:click={cancelConsent} />
	{/if}

	<main
		class="py-4 stack:py-[5vh] [@media(min-height:1216px)]:!py-16 flex flex-col items-center unstack:items-start unstack:flex-row stack:flex-col stack:items-center gap-x-col"
	>
		{#if closePageState}
			{$_('You can now close this window')}
		{:else}
			{#if showAppInfo}
				<AppInfo app={$data.app} isDeviceCodeFlow={$data.isDeviceCodeFlow} />
			{/if}

			<div class="unstack:w-1/2 max-w-col w-full stack:w-full">
				{#if showHeading && heading.length}
					<h1 data-test="page-heading" class="relative font-medium">
						{@html heading}
					</h1>
				{/if}

				<div class="px-indent" class:mt-2={showHeading}>
					<slot />

					{#if showCancelConsent}
						<div class="flex justify-start mt-4">
							<button
								data-test="cancel-btn"
								type="button"
								on:click|preventDefault={cancelConsent}
								class="btn-border w-full">{$_('Cancel')}</button
							>
						</div>
					{/if}
				</div>
			</div>
		{/if}
	</main>

	{#if !$data.isUserLoggedIn && !$data.preferred}
		<AuthorizeFooter />
	{/if}
{/if}
