<script>
	import { _, locale } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { onDestroy, onMount } from 'svelte';
	import { data, showSpinner, notification, isRemoteAuthClient } from '../../stores.js';
	import VerifyEmail from '../../lib/VerifyEmail.svelte';
	import Dropdown from '../../lib/Dropdown.svelte';
	import VerifyPhone from '../../lib/VerifyPhone.svelte';
	import LoginProvider from '../../lib/LoginProvider.svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import {
		getConsent,
		postConsent,
		deleteConsent,
		putName,
		postLoginProvider,
		postPicture,
		postLinkProvider,
		postLinkEth,
		postLinkEthChallenge
	} from '../../utils/api-calls.js';
	import logins from '../../../../../svr/providers/logins.json';
	import EthereumProgressModal from '../../lib/modal/EthereumProgressModal.svelte';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import {
		handleConsentResponse,
		getWallet,
		getDisplay,
		logPlausibleEvent,
		dedupe,
		makeLabel,
		clearSessionStorage,
		promptForPasskey
	} from '../../utils/helper.js';
	import HandlePicture from '../../lib/modal/HandlePictureModal.svelte';
	import VerifiedIcon from '../../lib/icon/VerifiedIcon.svelte';
	import MailIcon from '../../lib/icon/MailIcon.svelte';
	import { getLoginChallenge, postLoginChallenge } from '../../passkey.js';
	import { startAuthentication } from '@simplewebauthn/browser';
	import SpinnerIcon from '../../lib/icon/SpinnerIcon.svelte';
	// import { WALLETCONNECT_CONFIG } from '../../constants.js';
	// import { getAddressFromAccount } from '@walletconnect/utils';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html'

	dayjs.extend(relativeTime);

	let web3ModalSign;

	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];

	let submitFormRequest = false;

	let selectedName,
		selectedEmail,
		selectedPhone,
		selectedEthAddress,
		selectedPicture,
		selectedPreferredName,
		selectedFirstName,
		selectedLastName,
		selectedDiscord,
		selectedTwitter,
		selectedGithub,
		selectedGitlab;

	let selectedVerifiedName = '';
	let selectedExistingName = '';
	let selectedExistingUsername = '';

	let continueWithEmailForExisting = false;

	let addFullNameAjax = false;
	let addPreferredNameAjax = false;
	let addFirstNameAjax = false;
	let addLastNameAjax = false;

	let dropdownStates = {
		addNameDropdown: false,
		verifiedNameAccountsDropdown: false,
		existingNameAccountsDropdown: false,
		existingUsernameAccountsDropdown: false,
		nameDropdown: false,
		preferredNameDropdown: false,
		firstNameDropdown: false,
		lastNameDropdown: false,
		emailDropdown: false,
		phoneDropdown: false,
		pictureDropdown: false,
		ethAddressDropdown: false,
		emailAccountsDropdown: false,
		phoneAccountsDropdown: false,
		pictureAccountsDropdown: false,
		ethAccountsDropdown: false,
		discordAccountsDropdown: false,
		twitterAccountsDropdown: false,
		githubAccountsDropdown: false,
		gitlabAccountsDropdown: false
	};

	let uploadPictureAjax = false;

	let emailOTPState = false;
	let phoneOTPState = false;

	let loadingFailed = false;
	let noSession = false;

	//authorization was cancelled from initiating client
	let authCancelledAtInitClient = false;

	let authCancelledAtRemoteClient = false;

	let remoteAuthDone = false;
	let invalidQr = false;

	const formData = {
		name: '',
		preferredName: '',
		firstName: '',
		lastName: ''
	};

	let errData = null;

	//Add i18n keys
	let errMap = {
		invalid_request: 'Invalid Request',
		unauthorized_client: 'Unauthorized Client',
		'invalid response_mode': 'Invalid Response Mode',
		access_denied: 'Access Denied',
		unsupported_response_type: 'Unsupported Response Type',
		'must be code or id_token': 'Must be code or id token',
		server_error: 'Server Error',
		temporarily_unavailable: 'Temporarily Unavailable',
		invalid_client: 'Invalid Client',
		invalid_grant: 'Invalid Grant',
		unsupported_grant_type: 'Unsupported Grant Type',
		invalid_scope: 'Invalid Scope',
		'missing or invalid scope': 'Missing or invalid scope',
		'missing code_challenge': 'Missing code challenge',
		UNSUPPORTED_REDIRECT_URI: 'Redirect URI is not supported',
		ACCESS_DENIED: 'User does not have access to Dev Redirect URIs'
		// NO_SESSION_REQUEST: 'No Session'
	};

	let oauth_error = false;

	const helloAppIds = ['hello_console', 'hello_quick_start'];

	let evtSource;

	const searchParams = new URLSearchParams(window.location.search);
	const hashParams = new URLSearchParams(window.location.hash.substring(1));

	onMount(async () => {
		$showSpinner = true;

		const errorFromProvider = hashParams.get('error');
		const loadedFromQR = hashParams.get('qr');
		const loadedFromApp = searchParams.get('client_id');
		const responseFromProvider = hashParams.has('provider');
		const sessionQueryParams = sessionStorage.getItem('authorize_query_params');

		if (errorFromProvider) {
			//LINE returns ACCESS_DENIED (uppercase)
			//Apple returns user_cancelled_authorize
			if (
				['access_denied', 'user_cancelled_authorize'].includes(errorFromProvider?.toLowerCase())
			) {
				$notification = {
					text: $_('Request cancelled'),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			oauth_error = true;

			//User can cancel authorize flow on linking provider - make getConsent call since user can be still logged in
			try {
				$data = await getConsent('', { showNotification: !oauth_error });
			} catch (err) {
				return handleGetConsentError(err);
			}
		} else if (loadedFromQR) {
			try {
				$data = await getConsent('?qr=' + loadedFromQR, { showNotification: !oauth_error });
			} catch (err) {
				$notification = {
					text: 'QR has already been scanned',
					type: 'error'
				};
				invalidQr = true;
				return handleGetConsentError(err);
			}

			//DEBUG LOG
			const logObj = {};
			for (const [key, value] of hashParams.entries()) {
				logObj[key] = value;
			}
			console.log('Hash Params:', JSON.stringify(logObj, null, 2));
			//DEBUG LOG
		} else if (loadedFromApp) {
			//on logout, need original query params to make get consent call
			//useful if user wants to switch accounts
			sessionStorage.setItem('authorize_query_params', window.location.search);

			try {
				$data = await getConsent(window.location.search, { showNotification: !oauth_error });
			} catch (err) {
				return handleGetConsentError(err);
			}

			if (searchParams.has('scope') && searchParams.get('scope').includes('update_scope')) {
				logPlausibleEvent({ u: '/update_scope' });
			}

			//DEBUG LOG
			const logObj = {};
			for (const [key, value] of searchParams.entries()) {
				logObj[key] = value;
			}
			console.log('Query Params:', JSON.stringify(logObj, null, 2));
			//DEBUG LOG

			clearSessionStorage(['app', 'authorize_query_params', 'az_release_funnel']);
		} else if (responseFromProvider) {
			try {
				$data = await getConsent(location.hash.substring(1), { showNotification: !oauth_error }); //do not override oauth notification
			} catch (err) {
				return handleGetConsentError(err);
			}
			logProviderResPlausibleEvents();
		} else if (sessionQueryParams) {
			//we dont have consent data
			try {
				$data = await getConsent('', { showNotification: !oauth_error });
			} catch (err) {
				return handleGetConsentError(err);
			}
		} else {
			//redirect to wallet - no session found
			window.location.href = window.location.origin;
			return;
		}

		//Done processing query/hash params the page got loaded with
		//Clear url query params (oauth errors, provider response, authorize query params, qr param)
		window.history.replaceState({}, document.title, window.location.pathname);

		//Passkey auto login
		if (
			!$data.isUserLoggedIn &&
			$data.hasPasskey &&
			!sessionStorage.authorize_query_params?.includes('passkey--')
		) {
			try {
				const challenge = await getLoginChallenge();
				const signedChallenge = await startAuthentication(challenge);
				const webAuthnLoginRes = await postLoginChallenge(signedChallenge);
				if (!webAuthnLoginRes?.verified) throw webAuthnLoginRes;
				$data = await getConsent();
			} catch (err) {
				console.error(err);
				$notification = {
					text: 'Authentication cancelled',
					type: 'error'
				};
			}
		}

		//Social auto login (no prompt)
		if (!$data.isUserLoggedIn && $data.noPrompt && $data.preferred.length) {
			if (!['email', 'phone', 'ethereum'].includes($data.preferred[0].slug)) {
				const { redirect } = await postLoginProvider({
					slug: $data.preferred[0].slug,
					body: {
						preferred: $data.preferred[0].slug,
						login_hint: $data.preferred[0].login_hint
					},
					server: $data.preferred[0].mastodonServer
				});
				window.location.href = redirect;
				return;
			}
		}

		//Prompt for passkey
		const showPromptForPasskey = await promptForPasskey($data);
		if (showPromptForPasskey) return replaceRoute('/passkey');

		//Got data to respond back to app
		if ($data.uri && $data.response_mode) {
			if (!$data?.params?.error) {
				await logPlausibleEvent({ u: '/auto' });
			}
			return handleConsentResponse($data);
		}

		//Authorization success at remote client / IAB
		if ($data.success || $data.inApp) {
			remoteAuthDone = true;
			$notification = {
				text: 'Authorization is complete',
				type: 'success'
			};
			if (evtSource) {
				evtSource.close();
			}
			$showSpinner = false;
			return;
		}

		if (!$data.isUserLoggedIn) return replaceRoute('/login');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				console.log('keep-alive: ' + event.data);
			});
		}

		if (!wizardRoutes()) {
			logPlausibleEvent({ u: '/' });
			setStartingClaimValues();
			$showSpinner = false;
		}
	});

	async function handleGetConsentError(err) {
		if (err.status === 401) {
			await logPlausibleEvent({ u: '/no-session' });
			noSession = true;
		}
		const json = await err.json();
		const error = json?.error?.message || json?.params?.error || json?.error;
		if (error) {
			//DEBUG LOG
			const logObj = {};
			for (const [key, value] of searchParams.entries()) {
				logObj[key] = value;
			}
			console.log('Query Params:', JSON.stringify(logObj, null, 2));
			console.log('Response:', JSON.stringify(json, null, 2));
			//DEBUG LOG
			errData = json;

			if (errData.uri) {
				//if no response mode, default to fragment
				return handleConsentResponse(errData);
			}

			if (errMap[error]) {
				$notification = {
					text: errMap[error],
					type: 'error'
				};
			}
		}
		console.error(err);
		window.history.replaceState({}, document.title, window.location.pathname);
		loadingFailed = true;
		$showSpinner = false;
	}

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	function wizardRoutes() {
		if (!$data.release) return false;
		const routes = {
			recovery: '/wizard/recovery',
			verified_names: '/wizard/verified-name',
			existing_names: '/wizard/existing-name',
			existing_usernames: '/wizard/existing-username',
			names: '/wizard/name',
			nicknames: '/wizard/preferred-name',
			given_names: '/wizard/first-name',
			family_names: '/wizard/last-name',
			emails: '/wizard/email',
			phones: '/wizard/phone',
			ethereums: '/wizard/ethereum',
			discords: '/wizard/discord',
			twitters: '/wizard/twitter',
			githubs: '/wizard/github',
			gitlabs: '/wizard/gitlab'
		};
		const requested_scopes = Object.keys($data.release);
		const order_of_routes = Object.keys(routes);

		for (const route of order_of_routes) {
			if (!requested_scopes.includes(route)) continue;

			if (route === 'recovery') {
				if ($data.recovery?.length < 2) {
					return replaceRoute(routes[route]);
				}
			} else if (route === 'verified_names') {
				if (
					$data.release?.verified_names &&
					!Object.keys($data.release.verified_names).filter(
						(i) => Object.keys($data.release.verified_names[i]).length >= 2
					).length
				)
					return replaceRoute(routes[route]);
			} else if (route === 'existing_names') {
				if ($data.release?.existing_names && !Object.keys($data.release.existing_names).length)
					return replaceRoute(routes[route]);
			} else if (route === 'existing_usernames') {
				if (
					$data.release?.existing_usernames &&
					!Object.keys($data.release.existing_usernames).length
				)
					return replaceRoute(routes[route]);
			} else if (!$data.release[route].length) {
				return replaceRoute(routes[route]);
			}
		}
		return false;
	}

	function setStartingClaimValues() {
		if (Object.keys($data.release).length === 1 && $data.release?.sub) return;
		if ($data.release.verified_names) {
			const firstValidOccurrence = Object.keys($data.release.verified_names).filter(
				(i) => Object.keys($data.release.verified_names[i]).length >= 2
			)[0];
			selectedVerifiedName = firstValidOccurrence;
		}
		if ($data.release.existing_names) {
			const firstValidOccurrence = Object.keys($data.release.existing_names)[0];
			selectedExistingName = firstValidOccurrence;
		}
		if ($data.release.existing_usernames) {
			const firstValidOccurrence = Object.keys($data.release.existing_usernames)[0];
			selectedExistingUsername = firstValidOccurrence;
		}
		selectedPreferredName = $data.release.nicknames?.[0] || null;
		selectedFirstName = $data.release.given_names?.[0] || null;
		selectedLastName = $data.release.family_names?.[0] || null;
		selectedDiscord = $data.release.discords?.[0] || null;
		selectedTwitter = $data.release.twitters?.[0] || null;
		selectedGithub = $data.release.githubs?.[0] || null;
		selectedGitlab = $data.release.gitlabs?.[0] || null;

		if (
			Array.isArray($data?.release?.names) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.name
		) {
			selectedName = $data?.release?.previous.name;
		} else {
			selectedName = $data.release.names?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.emails) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.email
		) {
			selectedEmail = $data?.release?.previous.email;
		} else {
			selectedEmail = $data.release.emails?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.phones) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.phone
		) {
			selectedPhone = $data?.release?.previous.phone;
		} else {
			selectedPhone = $data.release.phones?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.pictures) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.picture
		) {
			//'previous' property only returns 'picture' value of picture object
			const pictureObj = $data?.release?.pictures.find(
				(i) => i.picture === $data?.release?.previous.picture
			);
			selectedPicture = pictureObj;
		} else {
			selectedPicture = $data.release.pictures?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.ethereums) &&
			$data?.release?.update_scope &&
			$data?.release?.previous
		) {
			//'previous' property only returns 'id' value of ethereum object
			const ethObj = $data?.release?.ethereums.find((i) => i.address === $data?.release?.previous);
			selectedEthAddress = ethObj;
		} else {
			selectedEthAddress = $data.release.ethereums?.[0] || null;
		}
	}

	async function logProviderResPlausibleEvents() {
		//End of Email Upgrade Funnel
		//email upgrade funnel state is valid and not already sent + log in success
		if (sessionStorage.email_upgrade_funnel === 'email_upgrade_start' && $data.isUserLoggedIn) {
			const email_domain = $data?.preferred?.[0]?.user_name?.split('@')[1];
			logPlausibleEvent({
				n: 'Email Upgrade Success',
				p: { email_domain, slug: hashParams.get('provider') },
				u: '/'
			});
			sessionStorage.removeItem('email_upgrade_funnel');
		}

		//New User Release Funnel
		const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
			sessionStorage.az_release_funnel
		);
		const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
		//session funnel state is valid and not already sent + logged in + not link flow
		if (
			indexOfCurrentFunnelStep !== -1 &&
			indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
			$data?.isUserLoggedIn &&
			$data?.notification?.type !== 'link'
		) {
			const provider = hashParams.get('provider');
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				// console.error(err)
			}
			try {
				$showSpinner = true;
				//we are await-ing because response can be a redirect back to app - we dont want to lose capturing events
				if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
					//existing user: not auto-flow + isNewUser + not rerelease
					await logPlausibleEvent({
						n: 'AZ Login Success',
						p: { client_id, provider, redirect },
						u: '/'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_success');
				} else {
					await logPlausibleEvent({
						n: 'AZ Existing User',
						p: { client_id, provider, redirect },
						u: '/'
					});
					sessionStorage.removeItem('az_release_funnel');
				}
			} catch (err) {
				console.error(err);
			} finally {
				$showSpinner = false;
			}
		}
	}

	function handleDropdown(toggledDropdown) {
		for (const i in dropdownStates) {
			if (i === toggledDropdown) {
				dropdownStates[i] = !dropdownStates[i];
				continue;
			}
			dropdownStates[i] = false;
		}
		phoneOTPState = emailOTPState = false;
	}

	async function submit() {
		let body = {};

		if (selectedVerifiedName && $data.release?.verified_names) {
			body.verified_name = selectedVerifiedName;
		}
		if (selectedExistingName && $data.release?.existing_names) {
			body.existing_name = selectedExistingName;
		}
		if (selectedExistingUsername && $data.release?.existing_usernames) {
			body.existing_username = selectedExistingUsername;
		}
		if (selectedName && $data.release?.names) {
			body.name = {
				value: selectedName,
				ordinal: $data.release.names.indexOf(selectedName)
			};
		}
		if (selectedPreferredName && $data.release?.nicknames) {
			body.nickname = {
				value: selectedPreferredName,
				ordinal: $data.release.nicknames.indexOf(selectedPreferredName)
			};
		}
		if (selectedFirstName && $data.release?.given_names) {
			body.given_name = {
				value: selectedFirstName,
				ordinal: $data.release.given_names.indexOf(selectedFirstName)
			};
		}
		if (selectedLastName && $data.release?.family_names) {
			body.family_name = {
				value: selectedLastName,
				ordinal: $data.release.family_names.indexOf(selectedLastName)
			};
		}
		if (selectedEmail && $data.release?.emails) {
			body.email = {
				value: selectedEmail,
				ordinal: $data.release.emails.indexOf(selectedEmail)
			};
		}
		if (selectedPhone && $data.release?.phones) {
			body.phone = {
				value: selectedPhone,
				ordinal: $data.release.phones.indexOf(selectedPhone)
			};
		}
		if (selectedEthAddress && $data.release?.ethereums) {
			const ethObj = {
				value: selectedEthAddress.address,
				ordinal: $data.release?.ethereums.findIndex((i) => i.address === selectedEthAddress.address)
			};
			body.ethereum = ethObj;
		}
		if (selectedPicture?.picture && $data.release?.pictures) {
			body.picture = {
				value: selectedPicture.picture,
				ordinal: $data.release.pictures.findIndex((i) => i.picture === selectedPicture.picture)
			};
		}
		if (selectedDiscord?.id && $data.release?.discords) {
			body.discord = {
				value: selectedDiscord, //{id, username}
				ordinal: $data.release.discords.findIndex((i) => i.id === selectedDiscord.id)
			};
		}
		if (selectedTwitter?.id && $data.release?.twitters) {
			body.twitter = {
				value: selectedTwitter, //{id, username}
				ordinal: $data.release.twitters.findIndex((i) => i.id === selectedTwitter.id)
			};
		}
		if (selectedGithub?.id && $data.release?.githubs) {
			body.github = {
				value: selectedGithub, //{id, username}
				ordinal: $data.release.githubs.findIndex((i) => i.id === selectedGithub.id)
			};
		}
		if (selectedGitlab?.id && $data.release?.gitlabs) {
			body.gitlab = {
				value: selectedGitlab, //{id, username}
				ordinal: $data.release.gitlabs.findIndex((i) => i.id === selectedGitlab.id)
			};
		}
		if ($data.release?.console) body.console = true;
		if ($data.release?.quickstart) body.quickstart = true;
		if ($data.release?.recovery) body.recovery = true;
		submitFormRequest = true;

		try {
			const res = await postConsent(body);

			//End of User Release Funnel
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_release');
			//session funnel state is valid and not already sent + new user (isNewUser flag + not rerelease (`previous` flag in claim object))
			if (
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				$data?.isNewUser &&
				!$data?.release?.previous
			) {
				await logPlausibleEvent({ n: 'AZ Release', u: '/' });
				sessionStorage.removeItem('az_release_funnel');
			}

			if ($isRemoteAuthClient) {
				remoteAuthDone = true;
				$notification = {
					text: 'Authorization is complete',
					type: 'success'
				};
				evtSource.close();
			} else {
				handleConsentResponse(res);
				return;
			}
		} catch (err) {
			//Handle NOT_USER_SUBJECT error
			if (err.status === 400) {
				err
					.json()
					.then((res) => {
						console.info(res);
						$notification = {
							text: $_('Something went wrong'),
							type: 'error'
						};
					})
					.catch((err) => console.error(err));
			} else {
				console.error(err);
			}
			submitFormRequest = false;
			// loadingFailed = true;
		}
	}

	async function addFullName() {
		addFullNameAjax = true;
		try {
			const res = await putName('name', formData.name);
			$data.release.names = res.names;
			selectedName = $data.release.names[0];
			dropdownStates.nameDropdown = false;
			dropdownStates.addNameDropdown = false;
			formData.name = '';
		} catch (err) {
			console.error(err);
		} finally {
			addFullNameAjax = false;
		}
	}

	async function addPreferredName() {
		addPreferredNameAjax = true;
		try {
			const res = await putName('nickname', formData.preferredName);
			$data.release.nicknames = res.nicknames;
			selectedPreferredName = $data.release.nicknames[0];
			dropdownStates.preferredNameDropdown = false;
			formData.preferredName = '';
		} catch (err) {
			console.error(err);
		} finally {
			addPreferredNameAjax = false;
		}
	}

	async function addFirstName() {
		addFirstNameAjax = true;
		try {
			const res = await putName('given_name', formData.firstName);
			$data.release.given_names = res.given_names;
			selectedFirstName = $data.release.given_names[0];
			dropdownStates.firstNameDropdown = false;
			formData.firstName = '';
		} catch (err) {
			console.error(err);
		} finally {
			addFirstNameAjax = false;
		}
	}

	async function addLastName() {
		addLastNameAjax = true;
		try {
			const res = await putName('family_name', formData.lastName);
			$data.release.family_names = res.family_names;
			selectedLastName = $data.release.family_names[0];
			dropdownStates.lastNameDropdown = false;
			formData.lastName = '';
		} catch (err) {
			console.error(err);
		} finally {
			addLastNameAjax = false;
		}
	}

	async function continueWithProvider(slug, attribute, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute,
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function verifyEmailSuccess() {
		try {
			const res = await getConsent();
			if (!res.merge) {
				$data.release.emails = res.release.emails;
				$data.release.accounts = res.release.accounts;
				if ($data.release.existing_usernames) {
					$data.release.existing_usernames = res.release.existing_usernames;
				}
				selectedEmail = $data.release.emails[0];
			}
			dropdownStates.emailDropdown = false;
			if (dropdownStates.emailAccountsDropdown) {
				dropdownStates.emailAccountsDropdown = false;
			}
			if (continueWithEmailForExisting) {
				continueWithEmailForExisting = false;
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function verifyPhoneSuccess() {
		try {
			const res = await getConsent();
			if (!res.merge) {
				$data.release.phones = res.release.phones;
				selectedPhone = $data.release.phones[0];
			}
			dropdownStates.phoneDropdown = false;
			if (dropdownStates.phoneAccountsDropdown) {
				dropdownStates.phoneAccountsDropdown = false;
			}
		} catch (err) {
			console.error(err);
		}
	}

	let uploadedPicture = null;

	let handlePictureAjax = false;
	// Picture upload start
	async function uploadPicture(picture) {
		handlePictureAjax = true;
		const formDataObj = new FormData();
		formDataObj.append('file', picture);

		try {
			const res = await postPicture(formDataObj);
			$data.release.pictures = res.pictures;
			selectedPicture = $data.release.pictures[0];
			dropdownStates.pictureDropdown = false;
			if (dropdownStates.pictureAccountsDropdown) {
				dropdownStates.pictureAccountsDropdown = false;
			}
		} catch (err) {
			console.error(err);
		} finally {
			uploadPictureAjax = false;
			handlePictureAjax = false;
		}
	}
	// Picture upload end

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign...')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_(`Message signed`),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_(`You've rejected the sign request`),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_(`Something went wrong`),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$notification = {
				text: $_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				type: 'success'
			};
			$notification = {
				text: $_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				type: 'success'
			};
			$data = await getConsent();
			if (!$data.merge) {
				selectedEthAddress = $data.release?.ethereums?.[0];
				dropdownStates.ethAddressDropdown = false;
				if (dropdownStates.ethAccountsDropdown) {
					dropdownStates.ethAccountsDropdown = false;
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	const trimEthAddress = (addr) => {
		return addr.slice(0, 6) + '...' + addr.slice(38);
	};

	let session;
	// async function continueWithWalletConnect() {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch(err) {
	// 		console.error(err)
	// 		setTimeout(() => { //tbd : remove timeout - something is unsetting notification here
	// 			$notification = {
	// 			text: 'Something went wrong',
	// 				type: 'error'
	// 			}
	// 		}, 150)
	// 		return;
	// 	}

	// 	if (session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign...')
	// 		}
	// 	];
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address)
	// 	}, 1000)
	// }

	async function continueWalletConnectSigning(address) {
		let challenge, signature;
		const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
			challenge = res.challenge;
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
			return;
		}
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign...')
			}
		];
		try {
			signature = await web3ModalSign.request({
				topic: session.topic,
				chainId: 'eip155:1',
				request: {
					method: 'personal_sign',
					params: [challenge, address]
				}
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_(`Message signed`),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			$notification = {
				text: $_(`You've rejected the sign request`),
				type: 'error'
			};
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const icon =
			session.peer.metadata.icons[0] ||
			(session.peer.metadata?.url === 'https://metamask.io/'
				? 'https://cdn.hello.coop/images/metamask.svg'
				: 'https://cdn.hello.coop/images/ethereum.svg');
		const body = {
			signature,
			address,
			icon,
			name: session.peer.metadata.name
		};

		try {
			await postLinkEthChallenge(body);
			logPlausibleEvent({
				u: `/link/ethereum/walletconnect/${slug}`,
				n: 'action'
			});
			$notification = {
				text: $_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				type: 'success'
			};
			$data = await getConsent();
			if (!$data.merge) {
				selectedEthAddress = $data.release?.ethereums?.[0];
				dropdownStates.ethAddressDropdown = false;
				if (dropdownStates.ethAccountsDropdown) {
					dropdownStates.ethAccountsDropdown = false;
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}

	function getHostName(uri) {
		if (!uri) return false;
		try {
			const hostname = new URL(uri)?.hostname;
			return hostname;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	function returnToApp() {
		let params = errData?.params || errData;
		if (errData?.error?.message) {
			//normalize error response object
			//example:
			// {
			// 	"error": {
			// 		"message": "ACCESS_DENIED",
			// 		"code": 403
			// 	}
			// }
			params = {
				error: errData.error.message?.toLowerCase(),
				error_description: '',
				error_uri: ''
			};
		}
		const searchParams = new URLSearchParams(sessionStorage.authorize_query_params);
		const errResponseObject = {
			uri: searchParams.get('redirect_uri'),
			response_type: searchParams.get('response_type'),
			response_mode: searchParams.get('response_mode'),
			params
		};
		return handleConsentResponse(errResponseObject);
	}

	function getAppNameFromSessionStorage() {
		if (!sessionStorage.app) return;
		try {
			const appName = JSON.parse(sessionStorage.app)?.name;
			return appName;
		} catch (err) {
			console.error(err);
			sessionStorage.removeItem('app');
			return false;
		}
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			} else if (ethereumProgressModal === 'walletconnect') {
				continueWalletConnectSigning(e.detail);
			}
		}}
	/>
{/if}

{#if uploadedPicture}
	<HandlePicture
		imgSrc={uploadedPicture}
		bind:ajaxRequest={handlePictureAjax}
		on:cancel={() => (uploadedPicture = null)}
		on:upload={(e) => {
			uploadPicture(e.detail);
			uploadedPicture = null;
		}}
	/>
{/if}

<AuthorizeLayout
	heading={$_('Requires your')}
	showHeading={$data?.release && Object.keys($data.release).length}
	showTitleBar={!authCancelledAtRemoteClient &&
		!authCancelledAtInitClient &&
		!remoteAuthDone &&
		Object.keys($data).length &&
		!$data?.uri}
	showDeviceInfo={$isRemoteAuthClient &&
		!authCancelledAtRemoteClient &&
		!authCancelledAtInitClient &&
		!remoteAuthDone}
	closePageState={authCancelledAtInitClient ||
		authCancelledAtRemoteClient ||
		remoteAuthDone ||
		invalidQr}
	showCancelConsent={false}
>
	<section class="max-w-md mx-auto">
		<div class="space-y-2 relative">
			<!-- Hide API Access section for first part apps unless console is the only requested scope -->
			{#if (($data.release?.console || $data.release?.quickstart) && Object.keys($data.release)?.length === 1) || (($data.release?.console || $data.release?.quickstart) && !helloAppIds.includes($data.app?.client_id))}
				<div data-test="api-access-container" class="space-y-1">
					<label for="api-access" class="text-sm font-medium">API Access</label>
					<div
						id="api-access"
						data-test="publisher-api-access-container"
						class="px-2 py-2 relative w-full h-auto ring-1 ring-charcoal dark:ring-[#808080] ring-opacity-60 dark:ring-opacity-60 text-charcoal dark:text-[#D4D4D4] rounded-md"
					>
						<ul class="space-y-1">
							{#each ['Create', 'Read', 'Update', 'Delete'] as op}
								<li class="text-left flex items-center gap-x-2">
									<input
										data-test={`publisher-${op}-checkbox`}
										id={`publisher-${op}-checkbox`}
										type="checkbox"
										class="form-checkbox !w-4 !h-4 opacity-60 !pointer-events-none"
										checked={$data.release.console ||
											(['Create', 'Read'].includes(op) && $data.release.quickstart)}
										disabled
									/>
									<label for={`publisher-${op}-checkbox`} class="pointer-events-none"
										>Publisher {op}</label
									>
								</li>
							{/each}
						</ul>
					</div>
					<div
						data-test="application-api-access-container"
						class="mt-2 px-2 py-2 relative w-full h-auto ring-1 ring-charcoal dark:ring-[#808080] ring-opacity-60 dark:ring-opacity-60 text-charcoal dark:text-[#D4D4D4] rounded-md"
					>
						<ul class="space-y-1">
							{#each ['Create', 'Read', 'Update', 'Delete'] as op}
								<li class="text-left flex items-center gap-x-2">
									<input
										type="checkbox"
										data-test={`application-${op}-checkbox`}
										id={`application-${op}-checkbox`}
										class="form-checkbox !w-4 !h-4 opacity-60 !pointer-events-none"
										checked={$data.release.console ||
											(['Create', 'Read'].includes(op) && $data.release.quickstart)}
										disabled
									/>
									<label for={`application-${op}-checkbox`} class="pointer-events-none"
										>Application {op}</label
									>
								</li>
							{/each}
						</ul>
					</div>
				</div>
			{/if}

			{#if selectedName}
				<div data-test="full-name-container" class="space-y-1">
					<label for="full-name" class="text-sm font-medium">
						{$_('Full Name (legal name)')}
					</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div data-test="names-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.names) as name, index (name)}
											<li data-test="name-{index}" class="flex items-center w-full">
												<input
													id="name-{name}"
													name="name"
													type="radio"
													bind:group={selectedName}
													value={name}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="name-{name}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<span class="truncate">
														{name}
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel={$_('Add another name')}
									expanded={dropdownStates.addNameDropdown}
									on:click={() => handleDropdown('addNameDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										{$_('Add another name')}
									</div>

									{#if dropdownStates.addNameDropdown}
										<form on:submit|preventDefault={addFullName} class="p-2" transition:slide|local>
											<input
												type="text"
												name="name"
												id="name"
												bind:value={formData.name}
												autocomplete="name"
												required
												placeholder={$_('enter your full name')}
												class="px-[16px] sm:px-[18px] w-full h-12"
											/>
											<button
												data-test="add-name-btn"
												type="submit"
												disabled={!formData.name.length || addFullNameAjax}
												class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
											>
												{#if addFullNameAjax}
													<SpinnerIcon css="h-5 w-5 text-white" />
												{:else if $data.release.names.length}
													<span>{$_('Add another name')}</span>
												{:else}
													<span>{$_('Add name')}</span>
												{/if}
											</button>
										</form>
									{/if}
								</Dropdown>
							</div>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="name-dropdown-btn"
							ariaLabel={selectedName}
							id="name-dropdown"
							expanded={dropdownStates.nameDropdown}
							on:click={() => handleDropdown('nameDropdown')}
						>
							<div
								data-test="selected-name"
								class="px-2 w-full h-12 flex items-center justify-between"
								aria-hidden="true"
							>
								{selectedName}
							</div>

							{#if dropdownStates.nameDropdown}
								{@const names = dedupe($data.release.names).filter((i) => i !== selectedName)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {names.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each names as name, index (name)}
											<li>
												<button
													data-test={`name-${index}`}
													on:click={() => {
														selectedName = name;
														dropdownStates.nameDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													{name}
												</button>
											</li>
										{/each}
									</ul>
									<form on:submit|preventDefault={addFullName} class="my-2">
										<input
											type="text"
											name="name"
											id="name"
											bind:value={formData.name}
											autocomplete="name"
											required
											placeholder={$_('enter your full name')}
											class="px-[16px] sm:px-[18px] w-full h-12"
										/>
										<button
											data-test="add-name-btn"
											type="submit"
											disabled={!formData.name.length || addFullNameAjax}
											class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
										>
											{#if addFullNameAjax}
												<SpinnerIcon css="h-5 w-5 text-white" />
											{:else if $data.release.names.length}
												<span>{$_('Add another name')}</span>
											{:else}
												<span>{$_('Add name')}</span>
											{/if}
										</button>
									</form>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedPreferredName}
				<div data-test="preferred-name-container" class="space-y-1">
					<label for="preferred-name" class="text-sm font-medium">
						{$_('Preferred Name (what you want to be called)')}
					</label>
					<Dropdown
						hasBackground={false}
						dataTest="preferred-name-dropdown-btn"
						ariaLabel={selectedPreferredName}
						id="preferred-name-dropdown"
						expanded={dropdownStates.preferredNameDropdown}
						on:click={() => handleDropdown('preferredNameDropdown')}
					>
						<div
							data-test="selected-preferred-name"
							class="px-2 w-full h-12 flex items-center justify-between"
							aria-hidden="true"
						>
							{selectedPreferredName}
						</div>
						{#if dropdownStates.preferredNameDropdown}
							{@const nicknames = dedupe($data.release.nicknames).filter(
								(i) => i !== selectedPreferredName
							)}
							<div
								class="px-indent rounded-md text-left w-full flex flex-col"
								transition:slide|local
							>
								<ul
									class="mt-0.5 {nicknames.length
										? 'border-y'
										: 'border-t'} border-[#808080] divide-y divide-[#808080]"
								>
									{#each nicknames as name, index (name)}
										<li>
											<button
												data-test={`preferred-name-${index}`}
												on:click={() => {
													selectedPreferredName = name;
													dropdownStates.preferredNameDropdown = false;
												}}
												class="flex-shrink-0 h-12 w-full flex items-center justify-start"
											>
												{name}
											</button>
										</li>
									{/each}
								</ul>

								<form on:submit|preventDefault={addPreferredName} class="my-2">
									<input
										type="text"
										name="nickname"
										id="nickname"
										bind:value={formData.preferredName}
										autocomplete="nickname"
										required
										placeholder={$_('enter what you want to be called')}
										class="px-[16px] sm:px-[18px] w-full h-12"
									/>
									<button
										data-test="add-preferred-name-btn"
										type="submit"
										disabled={!formData.preferredName.length || addPreferredNameAjax}
										class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
									>
										{#if addPreferredNameAjax}
											<SpinnerIcon css="h-5 w-5 text-white" />
										{:else if $data.release.nicknames.length}
											<span>{$_('Add another preferred name')}</span>
										{:else}
											<span>{$_('Add preferred name')}</span>
										{/if}
									</button>
								</form>
							</div>
						{/if}
					</Dropdown>
				</div>
			{/if}

			{#if selectedFirstName}
				<div data-test="first-name-container" class="space-y-1">
					<label for="first-name" class="text-sm font-medium">
						{$_('First Name (given name)')}
					</label>
					<Dropdown
						hasBackground={false}
						dataTest="first-name-dropdown-btn"
						id="first-name-dropdown"
						ariaLabel={selectedFirstName}
						expanded={dropdownStates.firstNameDropdown}
						on:click={() => handleDropdown('firstNameDropdown')}
					>
						<div
							data-test="selected-first-name"
							class="px-2 w-full h-12 flex items-center justify-between"
							aria-hidden="true"
						>
							{selectedFirstName}
						</div>

						{#if dropdownStates.firstNameDropdown}
							{@const givenNames = dedupe($data.release.given_names).filter(
								(i) => i !== selectedFirstName
							)}
							<div
								class="px-indent rounded-md text-left w-full flex flex-col"
								transition:slide|local
							>
								<ul
									class="mt-0.5 {givenNames.length
										? 'border-y'
										: 'border-t'} border-[#808080] divide-y divide-[#808080]"
								>
									{#each givenNames as name, index (name)}
										<li>
											<button
												data-test={`first-name-${index}`}
												on:click={() => {
													selectedFirstName = name;
													dropdownStates.firstNameDropdown = false;
												}}
												class="flex-shrink-0 h-12 w-full flex items-center justify-start"
											>
												{name}
											</button>
										</li>
									{/each}
								</ul>

								<form on:submit|preventDefault={addFirstName} class="my-2">
									<input
										type="text"
										name="given-name"
										id="given-name"
										bind:value={formData.firstName}
										autocomplete="given-name"
										required
										placeholder={$_('enter your first name')}
										class="px-[16px] sm:px-[18px] w-full h-12"
									/>
									<button
										data-test="add-first-name-btn"
										type="submit"
										disabled={!formData.firstName.length || addFirstNameAjax}
										class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
									>
										{#if addFirstNameAjax}
											<SpinnerIcon css="h-5 w-5 text-white" />
										{:else if $data.release.given_names.length}
											<span>{$_('Add another first name')}</span>
										{:else}
											<span>{$_('Add first name')}</span>
										{/if}
									</button>
								</form>
							</div>
						{/if}
					</Dropdown>
				</div>
			{/if}

			{#if selectedLastName}
				<div data-test="last-name-container" class="space-y-1">
					<label for="last-name" class="text-sm font-medium">
						{$_('Last Name (family name)')}
					</label>
					<Dropdown
						hasBackground={false}
						dataTest="last-name-dropdown-btn"
						id="last-name-dropdown"
						ariaLabel={selectedLastName}
						expanded={dropdownStates.lastNameDropdown}
						on:click={() => handleDropdown('lastNameDropdown')}
					>
						<div
							data-test="selected-last-name"
							class="px-2 w-full h-12 flex items-center justify-between"
							aria-hidden="true"
						>
							{selectedLastName}
						</div>

						{#if dropdownStates.lastNameDropdown}
							{@const familyNames = dedupe($data.release.family_names).filter(
								(i) => i !== selectedLastName
							)}
							<div
								class="px-indent rounded-md text-left w-full flex flex-col"
								transition:slide|local
							>
								<ul
									class="mt-0.5 {familyNames.length
										? 'border-y'
										: 'border-t'} border-[#808080] divide-y divide-[#808080]"
								>
									{#each familyNames as name, index (name)}
										<li>
											<button
												data-test={`last-name-${index}`}
												on:click={() => {
													selectedLastName = name;
													dropdownStates.lastNameDropdown = false;
												}}
												class="flex-shrink-0 h-12 w-full flex items-center justify-start"
											>
												{name}
											</button>
										</li>
									{/each}
								</ul>

								<form on:submit|preventDefault={addLastName} class="my-2">
									<input
										type="text"
										name="family-name"
										id="family-name"
										bind:value={formData.lastName}
										autocomplete="family-name"
										required
										placeholder={$_('enter your last name')}
										class="px-[16px] sm:px-[18px] w-full h-12"
									/>
									<button
										data-test="add-last-name-btn"
										type="submit"
										disabled={!formData.lastName.length || addLastNameAjax}
										class="flex items-center justify-center btn-background flex-shrink-0 w-full mt-2 disabled:opacity-50"
									>
										{#if addLastNameAjax}
											<SpinnerIcon css="h-5 w-5 text-white" />
										{:else if $data.release.family_names.length}
											<span>{$_('Add another last name')}</span>
										{:else}
											<span>{$_('Add last name')}</span>
										{/if}
									</button>
								</form>
							</div>
						{/if}
					</Dropdown>
				</div>
			{/if}

			{#if selectedEmail}
				<div data-test="email-container" class="space-y-1">
					<label for="email" class="text-sm font-medium">
						{$_('Verified Email Address')}
					</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div data-test="emails-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.emails) as email, index (email)}
											<li data-test="email-{index}" class="flex items-center w-full">
												<input
													id="email-{email}"
													name="email"
													type="radio"
													bind:group={selectedEmail}
													value={email}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="email-{email}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<span class="truncate">
														{email}
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel="Add another email"
									expanded={dropdownStates.emailAccountsDropdown}
									on:click={() => handleDropdown('emailAccountsDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										Add another email
									</div>

									{#if dropdownStates.emailAccountsDropdown}
										<ul class="space-y-2 p-2" transition:slide|local>
											<div class="pb-2">
												<VerifyEmail
													on:otp={() => (emailOTPState = true)}
													on:success={verifyEmailSuccess}
												/>
											</div>
											{#each logins.filter((i) => i.claims.verified_email) as provider}
												<LoginProvider
													{provider}
													prefix={Array.isArray($data?.accounts) &&
													$data?.accounts.includes(provider.slug)
														? 'Get another email from'
														: 'Get email from'}
													on:click={(e) => continueWithProvider(provider.slug, 'email', e.detail)}
												/>
											{/each}
										</ul>
									{/if}
								</Dropdown>
							</div>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="email-dropdown-btn"
							id="email-dropdown"
							ariaLabel={selectedEmail}
							expanded={dropdownStates.emailDropdown}
							on:click={() => handleDropdown('emailDropdown')}
						>
							<div
								data-test="selected-email"
								class="px-2 w-full h-12 flex items-center justify-between no-underline"
								aria-hidden="true"
							>
								{selectedEmail}
							</div>
							{#if dropdownStates.emailDropdown}
								{@const emails = dedupe($data.release.emails).filter((i) => i !== selectedEmail)}
								<div class="px-indent text-left w-full flex flex-col" transition:slide|local>
									<ul
										class="mt-0.5 {emails.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each emails as email, index (email)}
											<li>
												<button
													data-test={`email-${index}`}
													on:click={() => {
														selectedEmail = email;
														dropdownStates.emailDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start no-underline"
													class:opacity-60={email === selectedEmail}
												>
													{email}
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<VerifyEmail
											on:otp={() => (emailOTPState = true)}
											on:success={verifyEmailSuccess}
										/>
									</div>
									{#if !emailOTPState && logins.filter((i) => i.claims.verified_email).length}
										<ul class="space-y-2 mb-2">
											{#each logins.filter((i) => i.claims.verified_email) as provider}
												<li>
													<LoginProvider
														on:click={(e) => continueWithProvider(provider.slug, 'email', e.detail)}
														{provider}
														prefix="Get email from"
													/>
												</li>
											{/each}
										</ul>
									{/if}
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedPhone}
				<div data-test="phone-container" class="space-y-1">
					<label for="phone" class="text-sm font-medium">
						{$_('Verified Phone Number')}
					</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div data-test="phones-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.phones) as phone, index (phone)}
											<li data-test="phone-{index}" class="flex items-center w-full">
												<input
													id="phone-{phone}"
													name="phone"
													type="radio"
													bind:group={selectedPhone}
													value={phone}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="phone-{phone}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<span class="truncate">
														{window.intlTelInputUtils.formatNumber(
															phone,
															null,
															window.intlTelInputUtils.numberFormat.INTERNATIONAL
														)}
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel="Add another phone"
									expanded={dropdownStates.phoneAccountsDropdown}
									on:click={() => handleDropdown('phoneAccountsDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										Add another phone
									</div>

									{#if dropdownStates.phoneAccountsDropdown}
										<ul class="space-y-2 p-2" transition:slide|local>
											<div class="pb-2">
												<VerifyPhone
													on:otp={() => (phoneOTPState = true)}
													on:success={verifyPhoneSuccess}
												/>
											</div>
											{#each logins.filter((i) => i.claims.verified_phone) as provider}
												<LoginProvider
													{provider}
													prefix={Array.isArray($data?.accounts) &&
													$data?.accounts.includes(provider.slug)
														? 'Get another phone from'
														: 'Get phone from'}
													on:click={(e) => continueWithProvider(provider.slug, 'phone', e.detail)}
												/>
											{/each}
										</ul>
									{/if}
								</Dropdown>
							</div>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="phone-dropdown-btn"
							id="phone-dropdown"
							ariaLabel={window.intlTelInputUtils.formatNumber(
								selectedPhone,
								null,
								window.intlTelInputUtils.numberFormat.INTERNATIONAL
							)}
							expanded={dropdownStates.phoneDropdown}
							on:click={() => handleDropdown('phoneDropdown')}
						>
							<div
								data-test="selected-phone"
								class="px-2 w-full h-12 flex items-center justify-between"
								aria-hidden="true"
							>
								{window.intlTelInputUtils.formatNumber(
									selectedPhone,
									null,
									window.intlTelInputUtils.numberFormat.INTERNATIONAL
								)}
							</div>

							{#if dropdownStates.phoneDropdown}
								{@const phones = dedupe($data.release.phones).filter((i) => i !== selectedPhone)}
								<div class="px-indent text-left w-full flex flex-col" transition:slide>
									<ul
										class="mt-0.5 {phones.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each phones as phone, index (phone)}
											<li>
												<button
													data-test={`phone-${index}`}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
													on:click={() => {
														selectedPhone = phone;
														dropdownStates.phoneDropdown = false;
													}}
												>
													{window.intlTelInputUtils.formatNumber(
														phone,
														null,
														window.intlTelInputUtils.numberFormat.INTERNATIONAL
													)}
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<VerifyPhone
											on:otp={() => (phoneOTPState = true)}
											on:success={verifyPhoneSuccess}
										/>
									</div>

									{#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
										<ul class="space-y-2 mb-2">
											{#each logins.filter((i) => i.claims.verified_phone) as provider}
												<li>
													<LoginProvider
														on:click={(e) => continueWithProvider(provider.slug, 'phone', e.detail)}
														{provider}
														prefix="Get phone from"
													/>
												</li>
											{/each}
										</ul>
									{/if}
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedEthAddress}
				<div data-test="ethereum-container" class="space-y-1">
					<label for="eth-address" class="text-sm font-medium">
						{$_('Ethereum Address')}
					</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div data-test="eth-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.ethereums) as ethObj, index (ethObj.address)}
											<li data-test="eth-{index}" class="flex items-center w-full">
												<input
													id="eth-{ethObj.address}"
													name="email"
													type="radio"
													bind:group={selectedEthAddress}
													value={ethObj}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="eth-{ethObj.address}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="w-full flex items-center justify-between">
														<div class="flex items-center gap-x-2">
															<img
																src={selectedEthAddress?.wallet?.icon}
																class="w-4.5 max-h-[18px]"
																alt={selectedEthAddress?.wallet?.name}
															/>
															<span aria-hidden="true"
																>{selectedEthAddress?.wallet?.name} ({selectedEthAddress?.wallet
																	?.display})</span
															>
														</div>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel="Add another email"
									expanded={dropdownStates.ethAccountsDropdown}
									on:click={() => handleDropdown('ethAccountsDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										Add another wallet
									</div>

									{#if dropdownStates.ethAccountsDropdown}
										<ul class="space-y-2 p-2" transition:slide|local>
											{#if window.ethereum?.isMetaMask}
												<LoginProvider
													on:click={continueWithEthereumExtension}
													provider={{ display: 'MetaMask', slug: 'metamask' }}
													prefix="Get from"
												/>
											{:else if window.ethereum}
												<LoginProvider
													on:click={continueWithEthereumExtension}
													provider={{ display: 'Ethereum', slug: 'ethereum' }}
													prefix="Get from"
												/>
											{/if}

											<!-- Note: Uncomment to show WalletConnect -->
											<!-- <button
											class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
											on:click={continueWithWalletConnect}
										>
											<div class="flex items-center gap-x-4">
												<img
													src="https://cdn.hello.coop/images/walletconnect.svg"
													alt="WalletConnect"
													class="w-4.5 max-h-[18px]"
												/>
												<span class="block text-left">
													{$_('Get with {provider}', {
														values: { provider: 'WalletConnect' }
													})}
												</span>
											</div>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="flex-shrink-0 {$locale && $locale.startsWith('ar')
													? 'mr-auto rotate-90'
													: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												stroke-width="2"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M19 9l-7 7-7-7"
												/>
											</svg>
										</button> -->
										</ul>
									{/if}
								</Dropdown>
							</div>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							id="eth-address"
							expanded={dropdownStates.ethAddressDropdown}
							ariaLabel={`${selectedEthAddress?.wallet?.name} (${selectedEthAddress?.wallet?.display})`}
							on:click={() => handleDropdown('ethAddressDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div class="flex items-center gap-x-3">
									<img
										src={selectedEthAddress?.wallet?.icon}
										class="w-4.5 max-h-[18px]"
										alt={selectedEthAddress?.wallet?.name}
									/>
									<span aria-hidden="true"
										>{selectedEthAddress?.wallet?.name} ({selectedEthAddress?.wallet
											?.display})</span
									>
								</div>
							</div>

							{#if dropdownStates.ethAddressDropdown}
								{@const ethereums = dedupe($data.release.ethereums, 'address').filter(
									(i) => i.address !== selectedEthAddress.address
								)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {ethereums.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each ethereums as ethObj (ethObj.address)}
											<li>
												<button
													on:click={() => {
														selectedEthAddress = ethObj;
														dropdownStates.ethAddressDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													<div class="flex items-center gap-x-3">
														<img
															src={ethObj?.wallet?.icon}
															class="w-4.5 max-h-[18px]"
															alt={ethObj?.wallet?.display}
														/>
														<span>{ethObj?.wallet?.name} ({ethObj?.wallet?.display})</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2 space-y-2">
										{#if window.ethereum?.isMetaMask}
											<LoginProvider
												on:click={continueWithEthereumExtension}
												provider={{ display: 'MetaMask', slug: 'metamask' }}
												prefix="Get from"
											/>
										{:else if window.ethereum}
											<LoginProvider
												on:click={continueWithEthereumExtension}
												provider={{ display: 'Ethereum', slug: 'ethereum' }}
												prefix="Get from"
											/>
										{/if}

										<!-- Note: Uncomment to show WalletConnect -->
										<!-- <button
										class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
										on:click={continueWithWalletConnect}
									>
										<div class="flex items-center gap-x-4">
											<img
												src="https://cdn.hello.coop/images/walletconnect.svg"
												alt="WalletConnect"
												class="w-4.5 max-h-[18px]"
											/>
											<span class="block text-left">
												{$_('Get with {provider}', {
													values: { provider: 'WalletConnect' }
												})}
											</span>
										</div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="flex-shrink-0 {$locale && $locale.startsWith('ar')
												? 'mr-auto rotate-90'
												: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M19 9l-7 7-7-7"
											/>
										</svg>
									</button> -->
									</div>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedPicture}
				<div data-test="picture-container" class="space-y-1">
					<label for="picture" class="text-sm font-medium">
						{$_('Profile Picture')}
					</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div data-test="emails-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.pictures) as pictureObj, index (pictureObj.picture)}
											<li data-test="picture-{index}" class="flex items-center w-full">
												<input
													id="picture-{pictureObj.picture}"
													name="picture"
													type="radio"
													bind:group={selectedPicture}
													value={pictureObj}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="picture-{pictureObj.picture}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="flex items-center justify-center gap-x-2 relative w-11/12">
														<span
															class="flex-shrink-0 w-8 h-8 block rounded-full bg-gray-100 overflow-hidden"
														>
															<img
																src={pictureObj.picture}
																class="hide-alt-text flex-shrink-0 w-8 h-8 rounded-full object-cover"
																title={pictureObj.source === 'default'
																	? $_('Generic Hellō Profile Picture')
																	: pictureObj.source === 'upload'
																	? $_('Uploaded from device')
																	: $_('From {provider}', {
																			values: {
																				provider: pictureObj.source
																			}
																	  })}
																alt={pictureObj.source === 'default'
																	? $_('Generic Hellō Profile Picture')
																	: pictureObj.source === 'upload'
																	? $_('Uploaded from device')
																	: $_('From {provider}', {
																			values: {
																				provider: pictureObj.source
																			}
																	  })}
															/>
														</span>
														<span
															class="w-full truncate {$locale && $locale.startsWith('ar')
																? 'text-right'
																: 'text-left'}"
														>
															{#if pictureObj.source === 'default'}
																{$_('Generic Hellō Profile Picture')}
															{:else if pictureObj.source === 'upload'}
																{#if Date.now() - Date.parse(pictureObj.createdAt) < 24 * 60 * 60 * 1000}
																	{$_('Uploaded today')}
																{:else}
																	{$_('Uploaded {date}', {
																		values: {
																			date: dayjs(pictureObj.createdAt).locale($locale).fromNow()
																		}
																	})}
																{/if}
															{:else}
																{$_('From {provider}', {
																	values: {
																		provider: pictureObj.source || getDisplay(pictureObj.slug)
																	}
																})}

																{#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(pictureObj.slug) && pictureObj.label}
																	({pictureObj.label})
																{/if}

																{#if pictureObj.label !== 'gravatar'}
																	({Date.now() -
																		Date.parse(pictureObj.updatedAt || pictureObj.createdAt) <
																	24 * 60 * 60 * 1000
																		? pictureObj.updatedAt
																			? $_('Updated today')
																			: $_('Added today')
																		: $_(pictureObj.updatedAt ? 'Updated {date}' : 'Added {date}', {
																				values: {
																					date: dayjs(pictureObj.updatedAt || pictureObj.createdAt)
																						.locale($locale)
																						.fromNow()
																				}
																		  })})
																{/if}
															{/if}
														</span>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel="Add another picture"
									expanded={dropdownStates.pictureAccountsDropdown}
									on:click={() => handleDropdown('pictureAccountsDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										Add another picture
									</div>

									{#if dropdownStates.pictureAccountsDropdown}
										<ul class="space-y-2 p-2" transition:slide|local>
											<li>
												<button
													data-test="upload-picture-btn"
													on:click={() => {
														uploadPictureAjax = true;
														document.getElementById('picture-upload-ref').click();
														setTimeout(() => {
															uploadPictureAjax = false;
														}, 2022);
														/**
														 * TODO
														 * This is a hack to prevent closing of picture dropdown,
														 * clickOutside is fired because of input file window?
														 */
														setTimeout(() => {
															dropdownStates.pictureDropdown = true;
														}, 1);
													}}
													disabled={uploadPictureAjax}
													class="disabled:opacity-60 w-full relative btn-background overflow-hidden flex items-center {uploadPictureAjax
														? 'justify-center'
														: 'justify-start'} px-4"
												>
													<div class="w-full flex items-center justify-center">
														{#if uploadPictureAjax}
															<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
														{:else}
															<svg
																xmlns="http://www.w3.org/2000/svg"
																class="h-4.5"
																fill="none"
																viewBox="0 0 24 24"
																stroke="currentColor"
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	stroke-width="2"
																	d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
																/>
															</svg>
															<span
																class="block text-left {$locale && $locale.startsWith('ar')
																	? 'mr-4'
																	: 'ml-4'}"
															>
																{$_('Upload from device')}
															</span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																class="flex-shrink-0 {$locale && $locale.startsWith('ar')
																	? 'mr-auto'
																	: 'ml-auto'} h-4.5 opacity-80 transform -rotate-90 text-white dark:text-[#d4d4d4] group-focus:stroke-3 group-hover:stroke-3"
																fill="none"
																viewBox="0 0 24 24"
																stroke="currentColor"
																stroke-width="2"
																class:rotate-90={$locale && $locale.startsWith('ar')}
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	d="M19 9l-7 7-7-7"
																/>
															</svg>
														{/if}
														<input
															id="picture-upload-ref"
															type="file"
															accept="image/*"
															name="logo"
															hidden
															on:change={(e) =>
																(uploadedPicture = URL.createObjectURL(e.target.files[0]))}
															on:cancel={() => (uploadPictureAjax = false)}
														/>
													</div>
												</button>
											</li>
											{#each logins.filter((i) => i.claims.picture) as provider}
												<LoginProvider
													{provider}
													prefix={Array.isArray($data?.accounts) &&
													$data?.accounts.includes(provider.slug)
														? 'Get another picture from'
														: 'Get picture from'}
													on:click={(e) => continueWithProvider(provider.slug, 'picture', e.detail)}
												/>
											{/each}
										</ul>
									{/if}
								</Dropdown>
							</div>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="picture-dropdown-btn"
							id="picture-dropdown"
							ariaLabel="Profile Picture"
							expanded={dropdownStates.pictureDropdown}
							on:click={() => handleDropdown('pictureDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div
									data-test="selected-picture"
									class="flex items-center justify-center gap-x-4 w-11/12"
								>
									<img
										src={selectedPicture.picture}
										title={selectedPicture.source === 'default'
											? $_('Generic Hellō Profile Picture')
											: selectedPicture.source === 'upload'
											? $_('Uploaded from device')
											: $_('From {provider}', {
													values: {
														provider: selectedPicture.source
													}
											  })}
										alt={selectedPicture.source === 'default'
											? $_('Generic Hellō Profile Picture')
											: selectedPicture.source === 'upload'
											? $_('Uploaded from device')
											: $_('From {provider}', {
													values: {
														provider: selectedPicture.source
													}
											  })}
										class="hide-alt-text flex-shrink-0 w-8 h-8 rounded-full object-cover"
									/>
									<span
										class="w-full truncate {$locale && $locale.startsWith('ar')
											? 'text-right'
											: 'text-left'}"
									>
										{#if selectedPicture.source === 'default'}
											{$_('Generic Hellō Profile Picture')}
										{:else if selectedPicture.source === 'upload'}
											{Date.now() - Date.parse(selectedPicture.createdAt) < 24 * 60 * 60 * 1000
												? $_('Uploaded today')
												: $_('Uploaded {date}', {
														values: {
															date: dayjs(selectedPicture.createdAt).locale($locale).fromNow()
														}
												  })}
										{:else}
											{$_('From {provider}', {
												values: {
													provider: selectedPicture.source || getDisplay(selectedPicture.slug)
												}
											})}

											{#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(selectedPicture.slug) && selectedPicture.label}
												({selectedPicture.label})
											{/if}

											{#if selectedPicture.label !== 'gravatar'}
												({Date.now() -
													Date.parse(selectedPicture.updatedAt || selectedPicture.createdAt) <
												24 * 60 * 60 * 1000
													? selectedPicture.updatedAt
														? $_('Updated today')
														: $_('Added today')
													: $_(selectedPicture.updatedAt ? 'Updated {date}' : 'Added {date}', {
															values: {
																date: dayjs(selectedPicture.updatedAt || selectedPicture.createdAt)
																	.locale($locale)
																	.fromNow()
															}
													  })})
											{/if}
										{/if}
									</span>
								</div>
							</div>
							{#if dropdownStates.pictureDropdown}
								{@const pictures = dedupe($data.release.pictures, 'picture').filter(
									(i) => i.picture !== selectedPicture.picture
								)}
								<div class="px-indent text-left w-full flex flex-col" transition:slide|local>
									<ul
										class="mt-0.5 {pictures.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each pictures as pictureObj, index (pictureObj.picture)}
											<li>
												<button
													data-test={`picture-${index}`}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
													on:click={() => {
														selectedPicture = pictureObj;
														dropdownStates.pictureDropdown = false;
													}}
												>
													<div class="flex items-center justify-center gap-x-4 relative w-11/12">
														<span
															class="flex-shrink-0 w-8 h-8 block rounded-full bg-gray-100 overflow-hidden"
														>
															<img
																src={pictureObj.picture}
																class="hide-alt-text flex-shrink-0 w-8 h-8 rounded-full object-cover"
																title={pictureObj.source === 'default'
																	? $_('Generic Hellō Profile Picture')
																	: pictureObj.source === 'upload'
																	? $_('Uploaded from device')
																	: $_('From {provider}', {
																			values: {
																				provider: pictureObj.source
																			}
																	  })}
																alt={pictureObj.source === 'default'
																	? $_('Generic Hellō Profile Picture')
																	: pictureObj.source === 'upload'
																	? $_('Uploaded from device')
																	: $_('From {provider}', {
																			values: {
																				provider: pictureObj.source
																			}
																	  })}
															/>
														</span>
														<span
															class="w-full truncate {$locale && $locale.startsWith('ar')
																? 'text-right'
																: 'text-left'}"
														>
															{#if pictureObj.source === 'default'}
																{$_('Generic Hellō Profile Picture')}
															{:else if pictureObj.source === 'upload'}
																{#if Date.now() - Date.parse(pictureObj.createdAt) < 24 * 60 * 60 * 1000}
																	{$_('Uploaded today')}
																{:else}
																	{$_('Uploaded {date}', {
																		values: {
																			date: dayjs(pictureObj.createdAt).locale($locale).fromNow()
																		}
																	})}
																{/if}
															{:else}
																{$_('From {provider}', {
																	values: {
																		provider: pictureObj.source || getDisplay(pictureObj.slug)
																	}
																})}

																{#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(pictureObj.slug) && pictureObj.label}
																	({pictureObj.label})
																{/if}

																{#if pictureObj.label !== 'gravatar'}
																	({Date.now() -
																		Date.parse(pictureObj.updatedAt || pictureObj.createdAt) <
																	24 * 60 * 60 * 1000
																		? pictureObj.updatedAt
																			? $_('Updated today')
																			: $_('Added today')
																		: $_(pictureObj.updatedAt ? 'Updated {date}' : 'Added {date}', {
																				values: {
																					date: dayjs(pictureObj.updatedAt || pictureObj.createdAt)
																						.locale($locale)
																						.fromNow()
																				}
																		  })})
																{/if}
															{/if}
														</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>
									<ul class="space-y-2 my-2">
										<!-- Picture upload start -->
										<li>
											<button
												data-test="upload-picture-btn"
												on:click={() => {
													uploadPictureAjax = true;
													document.getElementById('picture-upload-ref').click();
													setTimeout(() => {
														uploadPictureAjax = false;
													}, 2022);
													/**
													 * TODO
													 * This is a hack to prevent closing of picture dropdown,
													 * clickOutside is fired because of input file window?
													 */
													setTimeout(() => {
														dropdownStates.pictureDropdown = true;
													}, 1);
												}}
												disabled={uploadPictureAjax}
												class="disabled:opacity-60 w-full relative btn-background overflow-hidden flex items-center {uploadPictureAjax
													? 'justify-center'
													: 'justify-start'} px-4"
											>
												<div class="w-full flex items-center justify-center">
													{#if uploadPictureAjax}
														<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
													{:else}
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-4.5"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
															/>
														</svg>
														<span
															class="block text-left {$locale && $locale.startsWith('ar')
																? 'mr-4'
																: 'ml-4'}"
														>
															{$_('Upload from device')}
														</span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="flex-shrink-0 {$locale && $locale.startsWith('ar')
																? 'mr-auto'
																: 'ml-auto'} h-4.5 opacity-80 transform -rotate-90 text-white dark:text-[#d4d4d4] group-focus:stroke-3 group-hover:stroke-3"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
															stroke-width="2"
															class:rotate-90={$locale && $locale.startsWith('ar')}
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																d="M19 9l-7 7-7-7"
															/>
														</svg>
													{/if}
													<input
														id="picture-upload-ref"
														type="file"
														accept="image/*"
														name="logo"
														hidden
														on:change={(e) =>
															(uploadedPicture = URL.createObjectURL(e.target.files[0]))}
														on:cancel={() => (uploadPictureAjax = false)}
													/>
												</div>
											</button>
										</li>
										{#each logins.filter((i) => i.claims.picture) as provider}
											<li>
												<LoginProvider
													on:click={(e) => continueWithProvider(provider.slug, 'picture', e.detail)}
													{provider}
													prefix="Get picture from"
												/>
											</li>
										{/each}
									</ul>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedVerifiedName}
				<div data-test="verified-name-container" id="verified-name" class="space-y-1">
					<label for="verified-name" class="text-sm font-medium">
						{$_('Name')}
					</label>
					<div id="verified-name" class="box-shadow space-y-2 p-2 rounded-md">
						{#if selectedVerifiedName && $data.release.verified_names}
							{@const verifiedNames = dedupe([...Object.keys($data.release.verified_names)]).sort(
								(a, b) =>
									Object.keys($data.release.verified_names[b]).length -
									Object.keys($data.release.verified_names[a]).length
							)}
							<div data-test="verified-name-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each verifiedNames as verified_name, index (verified_name)}
											{@const disabled =
												Object.keys($data.release.verified_names[verified_name]).length < 2}
											<li
												data-test="verified-name-{index}"
												class:opacity-50={disabled}
												class:pointer-events-none={disabled}
												class="flex items-center w-full"
											>
												<input
													id="verified-name-{verified_name}"
													name="verified-name"
													type="radio"
													bind:group={selectedVerifiedName}
													value={verified_name}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
													{disabled}
												/>
												<label
													for="verified-name-{verified_name}"
													class="ml-2 inline-flex items-center truncate w-full"
												>
													<span class="inline-block w-7 mr-0.5 flex-shrink-0">
														<VerifiedIcon unverified={disabled} />
													</span>
													<span class="truncate">
														{verified_name}
														{makeLabel($data.release.verified_names[verified_name])}
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>
						{/if}

						<div class="relative">
							<span data-test="link-more-name-cta" class="italic opacity-80 mb-1 block">
								Link another account to verify a different name
							</span>
							<Dropdown
								hasBackground={false}
								dataTest="add-provider-btn"
								ariaLabel={$_('Link another account')}
								expanded={dropdownStates.verifiedNameAccountsDropdown}
								on:click={() => handleDropdown('verifiedNameAccountsDropdown')}
							>
								<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
									{$_('Link another account')}
								</div>

								{#if dropdownStates.verifiedNameAccountsDropdown}
									<ul class="space-y-2 p-2" transition:slide|local>
										{#each logins.filter((i) => i.claims.existing_name) as provider}
											<LoginProvider
												{provider}
												prefix={Array.isArray($data?.accounts) &&
												$data?.accounts.includes(provider.slug)
													? 'Link another'
													: 'Link'}
												on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
											/>
										{/each}
									</ul>
								{/if}
							</Dropdown>
						</div>
					</div>
				</div>
			{/if}

			{#if selectedExistingName && !selectedVerifiedName}
				<div data-test="existing-name-container" id="existing-name" class="space-y-1">
					<label for="existing-name" class="text-sm font-medium">
						{$_('Name')}
					</label>
					<div id="existing-name" class="box-shadow space-y-2 p-2 rounded-md">
						{#if selectedExistingName && $data.release.existing_names}
							{@const existingNames = dedupe([...Object.keys($data.release.existing_names)]).sort(
								(a, b) =>
									Object.keys($data.release.existing_names[b]).length -
									Object.keys($data.release.existing_names[a]).length
							)}
							<div data-test="existing-name-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each existingNames as existing_name, index (existing_name)}
											<li data-test="existing-name-{index}" class="flex items-center w-full">
												<input
													id="existing-name-{existing_name}"
													name="existing-name"
													type="radio"
													bind:group={selectedExistingName}
													value={existing_name}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="existing-name-{existing_name}"
													class="ml-2 inline-flex items-center truncate w-full"
												>
													<span class="truncate">
														{existing_name}
														{makeLabel($data.release.existing_names[existing_name])}
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>
						{/if}

						<div class="relative">
							<span data-test="link-more-name-cta" class="italic opacity-80 mb-1 block">
								Link another account to verify a different name
							</span>
							<Dropdown
								hasBackground={false}
								dataTest="add-provider-btn"
								ariaLabel={$_('Link another account')}
								expanded={dropdownStates.existingNameAccountsDropdown}
								on:click={() => handleDropdown('existingNameAccountsDropdown')}
							>
								<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
									{$_('Link another account')}
								</div>

								{#if dropdownStates.existingNameAccountsDropdown}
									<ul class="space-y-2 p-2" transition:slide|local>
										{#each logins.filter((i) => i.claims.existing_name) as provider}
											<LoginProvider
												{provider}
												prefix={Array.isArray($data?.accounts) &&
												$data?.accounts.includes(provider.slug)
													? 'Link another'
													: 'Link'}
												on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
											/>
										{/each}
									</ul>
								{/if}
							</Dropdown>
						</div>
					</div>
				</div>
			{/if}

			{#if selectedExistingUsername}
				<div data-test="existing-username-container" id="existing-username" class="space-y-1">
					<label for="existing-username" class="text-sm font-medium">
						{$_('Username')} @verified.coop
					</label>
					<div id="existing-username" class="box-shadow space-y-2 p-2 rounded-md">
						{#if selectedExistingUsername && $data.release.existing_usernames}
							{@const existingUsernames = dedupe([
								...Object.keys($data.release.existing_usernames)
							])}
							<div data-test="existing-username-container" class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each existingUsernames as existing_username, index (existing_username)}
											<li data-test="existing-username-{index}" class="flex items-center w-full">
												<input
													id="existing-username-{existing_username}"
													name="existing-username"
													type="radio"
													bind:group={selectedExistingUsername}
													value={existing_username}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="existing-username-{existing_username}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<span class="truncate">
														@{existing_username}@verified.coop
													</span>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<div class="relative">
								<span data-test="link-more-username-cta" class="italic opacity-80 mb-1 block">
									Link another account to verify a different username
								</span>
								<Dropdown
									hasBackground={false}
									dataTest="add-provider-btn"
									ariaLabel={$_('Link another account')}
									expanded={dropdownStates.existingUsernameAccountsDropdown}
									on:click={() => handleDropdown('existingUsernameAccountsDropdown')}
								>
									<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
										{$_('Link another account')}
									</div>

									{#if dropdownStates.existingUsernameAccountsDropdown}
										<ul class="space-y-2 p-2" transition:slide|local>
											{#each logins.filter((i) => i.claims.existing_username) as provider}
												<LoginProvider
													{provider}
													prefix={Array.isArray($data?.accounts) &&
													$data?.accounts.includes(provider.slug)
														? 'Link another'
														: 'Link'}
													on:click={(e) => continueWithProvider(provider.slug, null, e.detail)}
												/>
												{#if provider.slug === 'google'}
													<Dropdown
														id="email-container"
														expanded={continueWithEmailForExisting}
														ariaLabel={$_('Add email')}
														dataTest="add-email-btn"
														on:click={() => {
															continueWithEmailForExisting = !continueWithEmailForExisting;
														}}
													>
														<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
															<MailIcon />
															<span class="block text-left" aria-hidden="true">
																{$_('Add email')}
															</span>
														</div>
														{#if continueWithEmailForExisting}
															<div class="px-2 pb-2 pt-1" transition:slide|local>
																<VerifyEmail on:success={verifyEmailSuccess} />
															</div>
														{/if}
													</Dropdown>
												{/if}
											{/each}
										</ul>
									{/if}
								</Dropdown>
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if selectedDiscord}
				<div data-test="discord-container" class="space-y-1">
					<label for="discord" class="text-sm font-medium">Discord Username</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.discords) as discord, index (discord.id)}
											<li data-test="discord-{index}" class="flex items-center w-full">
												<input
													id="discord-{discord.id}"
													name="discord"
													type="radio"
													bind:group={selectedDiscord}
													value={discord}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="discord-{discord.id}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="w-full flex items-center justify-between">
														<div class="flex items-center gap-x-2">
															<span aria-hidden="true">
																{discord.username}
															</span>
														</div>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<LoginProvider
								on:click={(e) => continueWithProvider('discord', null, e.detail)}
								provider={{ slug: 'discord' }}
								prefix="Add another"
							/>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="discord-dropdown"
							id="discord-dropdown"
							expanded={dropdownStates.discordAccountsDropdown}
							ariaLabel={selectedDiscord.username}
							on:click={() => handleDropdown('discordAccountsDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div data-test="selected-discord" class="flex items-center gap-x-3">
									<span aria-hidden="true">
										{selectedDiscord.username}
									</span>
								</div>
							</div>

							{#if dropdownStates.discordAccountsDropdown}
								{@const discords = dedupe($data.release.discords, 'id').filter(
									(i) => i.username !== selectedDiscord.username
								)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {discords.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each discords as discord (discord.id)}
											<li>
												<button
													on:click={() => {
														selectedDiscord = discord;
														dropdownStates.discordAccountsDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													<div class="flex items-center gap-x-3">
														<span>{discord.username}</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<LoginProvider
											on:click={(e) => continueWithProvider('discord', null, e.detail)}
											provider={{ slug: 'discord' }}
											prefix="Add another"
										/>
									</div>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedTwitter}
				<div data-test="twitter-container" class="space-y-1">
					<label for="twitter" class="text-sm font-medium">X (Twitter) Username</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.twitters) as twitter, index (twitter.id)}
											<li data-test="twitter-{index}" class="flex items-center w-full">
												<input
													id="twitter-{twitter.id}"
													name="twitter"
													type="radio"
													bind:group={selectedTwitter}
													value={twitter}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="twitter-{twitter.id}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="w-full flex items-center justify-between">
														<div class="flex items-center gap-x-2">
															<span aria-hidden="true">
																{twitter.username}
															</span>
														</div>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<LoginProvider
								on:click={(e) => continueWithProvider('twitter', null, e.detail)}
								provider={{ slug: 'twitter' }}
								prefix="Add another"
							/>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="twitter-dropdown"
							id="twitter-dropdown"
							expanded={dropdownStates.twitterAccountsDropdown}
							ariaLabel={selectedTwitter.username}
							on:click={() => handleDropdown('twitterAccountsDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div data-test="selected-twitter" class="flex items-center gap-x-3">
									<span aria-hidden="true">
										{selectedTwitter.username}
									</span>
								</div>
							</div>

							{#if dropdownStates.twitterAccountsDropdown}
								{@const twitters = dedupe($data.release.twitters, 'id').filter(
									(i) => i.username !== selectedTwitter.username
								)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {twitters.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each twitters as twitter (twitter.id)}
											<li>
												<button
													on:click={() => {
														selectedTwitter = twitter;
														dropdownStates.twitterAccountsDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													<div class="flex items-center gap-x-3">
														<span>{twitter.username}</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<LoginProvider
											on:click={(e) => continueWithProvider('twitter', null, e.detail)}
											provider={{ slug: 'twitter' }}
											prefix="Add another"
										/>
									</div>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedGithub}
				<div data-test="github-container" class="space-y-1">
					<label for="github" class="text-sm font-medium">GitHub Username</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.githubs) as github, index (github.id)}
											<li data-test="github-{index}" class="flex items-center w-full">
												<input
													id="github-{github.id}"
													name="github"
													type="radio"
													bind:group={selectedGithub}
													value={github}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="github-{github.id}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="w-full flex items-center justify-between">
														<div class="flex items-center gap-x-2">
															<span aria-hidden="true">
																{github.username}
															</span>
														</div>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<LoginProvider
								on:click={(e) => continueWithProvider('github', null, e.detail)}
								provider={{ slug: 'github' }}
								prefix="Add another"
							/>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="github-dropdown"
							id="github-dropdown"
							expanded={dropdownStates.githubAccountsDropdown}
							ariaLabel={selectedGithub.username}
							on:click={() => handleDropdown('githubAccountsDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div data-test="selected-github" class="flex items-center gap-x-3">
									<span aria-hidden="true">
										{selectedGithub.username}
									</span>
								</div>
							</div>

							{#if dropdownStates.githubAccountsDropdown}
								{@const githubs = dedupe($data.release.githubs, 'id').filter(
									(i) => i.username !== selectedGithub.username
								)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {githubs.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each githubs as github (github.id)}
											<li>
												<button
													on:click={() => {
														selectedGithub = github;
														dropdownStates.githubAccountsDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													<div class="flex items-center gap-x-3">
														<span>{github.username}</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<LoginProvider
											on:click={(e) => continueWithProvider('github', null, e.detail)}
											provider={{ slug: 'github' }}
											prefix="Add another"
										/>
									</div>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}

			{#if selectedGitlab}
				<div data-test="gitlab-container" class="space-y-1">
					<label for="gitlab" class="text-sm font-medium">GitLab Username</label>
					{#if $data?.release?.update_scope}
						<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
							<div class="relative flex items-center">
								<div class="w-full">
									<ul class="flex flex-col items-start space-y-2 mt-1">
										{#each dedupe($data?.release?.gitlabs) as gitlab, index (gitlab.id)}
											<li data-test="gitlab-{index}" class="flex items-center w-full">
												<input
													id="gitlab-{gitlab.id}"
													name="gitlab"
													type="radio"
													bind:group={selectedGitlab}
													value={gitlab}
													class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
												/>
												<label
													for="gitlab-{gitlab.id}"
													class="ml-2.5 inline-flex items-center truncate w-full"
												>
													<div class="w-full flex items-center justify-between">
														<div class="flex items-center gap-x-2">
															<span aria-hidden="true">
																{gitlab.username}
															</span>
														</div>
													</div>
												</label>
											</li>
										{/each}
									</ul>
								</div>
							</div>

							<LoginProvider
								on:click={(e) => continueWithProvider('gitlab', null, e.detail)}
								provider={{ slug: 'gitlab' }}
								prefix="Add another"
							/>
						</div>
					{:else}
						<Dropdown
							hasBackground={false}
							dataTest="gitlab-dropdown"
							id="gitlab-dropdown"
							expanded={dropdownStates.gitlabAccountsDropdown}
							ariaLabel={selectedGitlab.username}
							on:click={() => handleDropdown('gitlabAccountsDropdown')}
						>
							<div class="px-2 w-full h-12 flex items-center justify-between">
								<div data-test="selected-gitlab" class="flex items-center gap-x-3">
									<span aria-hidden="true">
										{selectedGitlab.username}
									</span>
								</div>
							</div>

							{#if dropdownStates.gitlabAccountsDropdown}
								{@const gitlabs = dedupe($data.release.gitlabs, 'id').filter(
									(i) => i.username !== selectedGitlab.username
								)}
								<div
									class="px-indent rounded-md text-left w-full flex flex-col"
									transition:slide|local
								>
									<ul
										class="mt-0.5 {gitlabs.length
											? 'border-y'
											: 'border-t'} border-[#808080] divide-y divide-[#808080]"
									>
										{#each gitlabs as gitlab (gitlab.id)}
											<li>
												<button
													on:click={() => {
														selectedGitlab = gitlab;
														dropdownStates.gitlabAccountsDropdown = false;
													}}
													class="flex-shrink-0 h-12 w-full flex items-center justify-start"
												>
													<div class="flex items-center gap-x-3">
														<span>{gitlab.username}</span>
													</div>
												</button>
											</li>
										{/each}
									</ul>

									<div class="my-2">
										<LoginProvider
											on:click={(e) => continueWithProvider('gitlab', null, e.detail)}
											provider={{ slug: 'gitlab' }}
											prefix="Add another"
										/>
									</div>
								</div>
							{/if}
						</Dropdown>
					{/if}
				</div>
			{/if}
		</div>

		<div class="flex flex-col items-center unstack:items-start stack:items-center my-6">
			<span>{$_('By continuing, you agree to the')}</span>
			<span class="block text-xl font-medium my-0.5" data-test="app-info-name"
				>{$data.app.name}</span
			>
			<div class="flex gap-x-4 text-sm pb-1">
				{#if $data.app.tos_uri}
					<a href={$data.app.tos_uri} class="underline" data-test="app-info-tos"
						>{$_('Terms of Service')}</a
					>
				{:else}
					<span class="text-red-500">{$_('No Terms of Service')}</span>
				{/if}
				{#if $data.app.pp_uri}
					<a href={$data.app.pp_uri} class="underline" data-test="app-info-pp"
						>{$_('Privacy Policy')}</a
					>
				{:else}
					<span class="text-red-500">{$_('No Privacy Policy')}</span>
				{/if}
			</div>
		</div>

		<div class="flex flex-col-reverse unstack:flex-row justify-between gap-y-4">
			<button
				data-test="cancel-btn"
				on:click|preventDefault={cancelConsent}
				class="btn-border unstack:w-36">{$_('Cancel')}</button
			>
			<!-- svelte-ignore a11y-autofocus -->
			<button
				autofocus={window && window.innerHeight > 1000}
				data-test="continue-btn"
				class="btn-background unstack:w-36 disabled:opacity-50 disabled:cursor-not-allowed"
				on:click={submit}
				disabled={submitFormRequest}
			>
				{#if submitFormRequest}
					<SpinnerIcon css="h-5 w-5 mx-auto text-white" />
				{:else}
					{$_('Continue')}
				{/if}
			</button>
		</div>
	</section>
</AuthorizeLayout>

{#if loadingFailed}
	<div class="text-center p-10">
		{#if noSession}
			No Session - <a href={window.location.origin} class="hover:underline focus:underline"
				>Go to Hellō Wallet</a
			>
		{:else}
			{@const searchParams = new URLSearchParams(sessionStorage.authorize_query_params)}
			{@const appName = getAppNameFromSessionStorage()}
			{@const redirect = getHostName(searchParams.get('redirect_uri'))}
			{@const validResponseType = ['code', 'id_token'].includes(searchParams.get('response_type'))}
			{#if redirect && validResponseType}
				<button on:click={returnToApp} class="hover:underline focus:underline"
					>Return to {appName || redirect}</button
				>
			{:else}
				<button on:click={() => history.back()} class="hover:underline focus:underline"
					>Go back</button
				>
			{/if}
		{/if}
	</div>
{/if}

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}

	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
