<script>
	import { _, locale } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import { showSpinner, isRemoteAuthClient } from '../../stores.js';
	import VerifyEmail from '../../lib/VerifyEmail.svelte';
	import VerifyPhone from '../../lib/VerifyPhone.svelte';
	import MailIcon from '../../lib/icon/MailIcon.svelte';
	import PhoneIcon from '../../lib/icon/PhoneIcon.svelte';
	import { data, notification } from '../../stores.js';
	import Dropdown from '../../lib/Dropdown.svelte';
	import LoginProvider from '../../lib/LoginProvider.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import tippy from 'sveltejs-tippy';
	import {
		getConsent,
		postLinkEth,
		postLinkEthChallenge,
		deleteConsent,
		postLinkProvider
	} from '../../utils/api-calls.js';
	import LoginProviderGroup from '../../lib/LoginProviderGroup.svelte';
	import { onDestroy, onMount } from 'svelte';
	import EthereumProgressModal from '../../lib/modal/EthereumProgressModal.svelte';
	import { logPlausibleEvent, getWallet, getDisplay, trimEthAddress } from '../../utils/helper.js';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import { WALLETCONNECT_CONFIG } from '../../constants.js';
	import { getAddressFromAccount } from '@walletconnect/utils';
	import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';

	let web3ModalSign;

	let continueWithEmail = false;
	let continueWithPhone = false;

	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];

	let authCancelledAtRemoteClient = false;
	let authCancelledAtInitClient = false;
	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isUserLoggedIn) return replaceRoute('/login');

		//we already got 2 recoveries
		if ($data.recovery?.length >= 2) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				console.log('keep-alive: ' + event.data);
			});
		}

		logPlausibleEvent({ u: '/wizard/recovery' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function verifyEmailSuccess() {
		try {
			continueWithEmail = false;
			$data = await getConsent();
		} catch (err) {
			console.error(err);
		}
	}

	async function verifyPhoneSuccess() {
		try {
			continueWithPhone = false;
			$data = await getConsent();
		} catch (err) {
			console.error(err);
		}
	}

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign...')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_(`You've rejected the sign request`),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			$notification = {
				text: $_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				type: 'success'
			};
			logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$data = await getConsent();
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	let session;
	async function continueWithWalletConnect() {
		try {
			web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
		} catch (err) {
			console.error(err);
			setTimeout(() => {
				//tbd : remove timeout - something is unsetting notification here
				$notification = {
					text: 'Something went wrong',
					type: 'error'
				};
			}, 150);
			return;
		}

		if (session) {
			await web3ModalSign.disconnect({
				topic: session.topic
			});
		}
		session = await web3ModalSign.connect({
			requiredNamespaces: {
				eip155: {
					methods: ['personal_sign'],
					chains: ['eip155:1'],
					events: []
				}
			}
		});
		const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
		ethereumProgressModal = 'walletconnect';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
		setTimeout(() => {
			continueWalletConnectSigning(address);
		}, 1000);
	}

	async function continueWalletConnectSigning(address) {
		let challenge, signature;
		const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
			challenge = res.challenge;
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
			return;
		}
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign...')
			}
		];
		try {
			signature = await web3ModalSign.request({
				topic: session.topic,
				chainId: 'eip155:1',
				request: {
					method: 'personal_sign',
					params: [challenge, address]
				}
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			$notification = {
				text: $_(`You've rejected the sign request`),
				type: 'error'
			};
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const icon =
			session.peer.metadata.icons[0] ||
			(session.peer.metadata?.url === 'https://metamask.io/'
				? 'https://cdn.hello.coop/images/metamask.svg'
				: 'https://cdn.hello.coop/images/ethereum.svg');
		const body = {
			signature,
			address,
			icon,
			name: session.peer.metadata.name
		};

		try {
			await postLinkEthChallenge(body);
			logPlausibleEvent({
				u: `/link/ethereum/walletconnect/${slug}`,
				n: 'action'
			});
			$notification = {
				text: $_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				type: 'success'
			};
			$data = await getConsent();
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	const displayString = (account) => {
		let string = '';
		if (account.slug === 'ethereum') {
			string += account.wallet?.name || 'Ethereum';
		} else if (account.slug === 'email' || account.slug === 'phone') {
			// Do nothing
		} else {
			string += getDisplay(account.slug);
		}
		if (account.slug === 'email') {
			string += account.user_name;
		} else if (account.slug === 'phone') {
			string += window.intlTelInputUtils.formatNumber(
				account.user_name,
				null,
				window.intlTelInputUtils.numberFormat.INTERNATIONAL
			);
		} else if (account.user_name) {
			if (account.mastodonServer) {
				string += ` (@${account.user_name}@${account.mastodonServer})`;
			} else {
				string += ` (${account.user_name})`;
			}
		}
		return string;
	};

	function nextPage() {
		if ($data?.recovery?.length >= 2) {
			navigateTo('/');
		}
	}

	$: {
		if ($data?.isUserLoggedIn) {
			nextPage();
		}
	}

	$: linkedProviders =
		Array.isArray($data.preferred) && Array.isArray($data.recovery)
			? [...$data?.preferred, ...$data?.recovery]
			: [];

	$: ignoreProviders = [...new Set(linkedProviders.map((i) => i.slug))];

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'email',
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			} else if (ethereumProgressModal === 'walletconnect') {
				continueWalletConnectSigning(e.detail);
			}
		}}
	/>
{/if}

<AuthorizeLayout
	heading="Requires 3 independent login providers<br /> to verify your identity"
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<section class="max-w-md container mx-auto">
		<div
			data-test="login-providers"
			class="mt-2 p-3 w-full rounded-md bg-[#DCDCDC] dark:bg-[#505050] text-charcoal dark:text-[#D4D4D4] {$locale &&
			$locale.startsWith('ar')
				? 'text-right'
				: 'text-left'}"
		>
			<label for="recovery-providers" class="font-bold text-lg">Login Providers</label>
			<div id="recovery-providers" class="my-1 space-y-1">
				{#each linkedProviders as provider, index}
					<div
						data-test="login-provider-{index}"
						class="flex items-center w-full my-1 pl-4 truncate gap-x-2"
					>
						{#if provider.slug === 'email'}
							<MailIcon />
						{:else if provider.slug === 'phone'}
							<PhoneIcon />
						{:else if provider.slug === 'ethereum'}
							<img
								src={provider.wallet?.icon || `https://cdn.hello.coop/images/${provider.slug}.svg`}
								alt={provider.wallet?.name}
								class="w-4.5 max-h-[18px]"
							/>
						{:else if provider.mastodonServer}
							<img
								src="https://{provider.mastodonServer}/favicon.ico"
								alt="Mastodon"
								class="w-4.5 max-h-[18px]"
							/>
						{:else}
							<img
								src="https://cdn.hello.coop/images/{provider.slug}.svg"
								alt={provider.slug}
								class="w-4.5 max-h-[18px] {['apple', 'twitter'].includes(provider.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
									? 'invert dark:invert-20'
									: ''}"
							/>
						{/if}
						<div class="truncate">
							{#if provider.slug === 'ethereum'}
								<span use:tippy={{ content: provider.login_hint, placement: 'top' }}>
									{displayString(provider)}
								</span>
							{:else}
								<span>
									{displayString(provider)}
								</span>
							{/if}
						</div>
					</div>
				{:else}
					<div class="font-medium italic ml-4">None linked yet</div>
				{/each}
			</div>
		</div>

		<p
			data-test="page-cta"
			class="md:max-w-md text-lg font-medium mb-6 mx-auto text-center block mt-6"
		>
			Link {3 - linkedProviders.length} more login provider{3 - linkedProviders.length !== 1
				? 's'
				: ''}
		</p>

		<div class="mt-6 max-w-md mx-auto">
			<LoginProviderGroup
				prefix="Link"
				{ignoreProviders}
				on:ethereum={continueWithEthereumExtension}
				on:walletconnect={continueWithWalletConnect}
			>
				{#if !ignoreProviders.includes('email')}
					<Dropdown
						id="email-container"
						expanded={continueWithEmail}
						ariaLabel={$_('Add email')}
						dataTest="add-email-btn"
						on:click={() => {
							continueWithEmail = !continueWithEmail;
							continueWithPhone = false;
						}}
					>
						<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
							<MailIcon />
							<span class="block text-left" aria-hidden="true">
								{$_('Add email')}
							</span>
						</div>
						{#if continueWithEmail}
							<div class="px-3 pb-3 pt-1" transition:slide|local>
								<VerifyEmail on:success={verifyEmailSuccess} />
								<section class="space-y-3 mt-5">
									{#each logins.filter((i) => i.claims.verified_email) as provider}
										<LoginProvider
											on:click={(e) => continueWithProvider(provider.slug, e.detail)}
											{provider}
											prefix="Get email from"
										/>
									{/each}
								</section>
							</div>
						{/if}
					</Dropdown>
				{/if}

				{#if !ignoreProviders.includes('phone')}
					<Dropdown
						expanded={continueWithPhone}
						ariaLabel={$_('Add phone')}
						on:click={() => {
							continueWithPhone = !continueWithPhone;
							continueWithEmail = false;
						}}
					>
						<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
							<PhoneIcon />
							<span class="block text-left" aria-hidden="true">
								{$_('Add phone')}
							</span>
						</div>

						{#if continueWithPhone}
							<div class="px-3 pb-3 pt-1" transition:slide|local>
								<VerifyPhone on:success={verifyPhoneSuccess} />
							</div>
						{/if}
					</Dropdown>
				{/if}
			</LoginProviderGroup>
		</div>
	</section>
</AuthorizeLayout>
