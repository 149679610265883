<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import Modal from '../../lib/modal/Modal.svelte';
	import VerifyOTP from '../../lib/VerifyOTP.svelte';
	import { data, showSpinner, notification, isRemoteAuthClient } from '../../stores.js';
	import { onDestroy, onMount } from 'svelte';
	import {
		getConsent,
		deleteConsent,
		postLinkEmail,
		postVerifyEmail,
		postLinkEmailCode,
		postVerifyEmailCode,
		postLinkProvider
	} from '../../utils/api-calls';
	import logins from '../../../../../svr/providers/logins.json';
	import LoginProvider from '../../lib/LoginProvider.svelte';
	import { logPlausibleEvent } from '../../utils/helper.js';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import SpinnerIcon from '../../lib/icon/SpinnerIcon.svelte';

	let value = '';
	let showOTPModal = false;
	let ajaxRequest = false;

	let ajaxRequestSendOTP = false;
	let ajaxRequestResendOTP = false;
	let otp = '';

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;

	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data.isUserLoggedIn) return replaceRoute('/login');

		//we already got an email
		if ($data.release?.emails?.length) return replaceRoute('/');

		//populate email input field with unverified email
		if ($data.release?.unverified_emails?.length) value = $data.release?.unverified_emails[0];

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				console.log('keep-alive: ' + event.data);
			});
		}

		logPlausibleEvent({ u: '/wizard/email' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function verifyEmail(resend = false) {
		try {
			ajaxRequest = true;
			// Check if user is trying to add unverified email
			if (
				$data.release?.unverified_emails?.length &&
				$data.release?.unverified_emails.includes(value)
			) {
				resend ? await postVerifyEmail(value, true) : await postVerifyEmail(value);
			} else {
				resend ? await postLinkEmail(value, true) : await postLinkEmail(value);
			}
			showOTPModal = true;
		} catch (err) {
			if (err.status === 401) {
				const { error } = await err.json();
				if (error === 'EMAIL_ALREADY_VERIFIED') {
					$notification = {
						text: $_('{contact} has already been verified', {
							values: {
								contact: value
							}
						}),
						type: 'error'
					};
				}
			} else {
				console.error(err);
			}
		} finally {
			ajaxRequest = ajaxRequestResendOTP = false;
		}
	}

	async function verifyEmailCode(code) {
		try {
			ajaxRequestSendOTP = true;
			//Check if user is trying to add unverified email
			if (
				$data.release?.unverified_emails?.length &&
				$data.release?.unverified_emails.includes(value)
			) {
				await postVerifyEmailCode(code);
			} else {
				await postLinkEmailCode(code, false);
			}
			$data = await getConsent();
			$notification = {};
			if (!$data?.merge) {
				navigateTo('/');
			}
		} catch (err) {
			console.error(err);
			otp = '';
		} finally {
			ajaxRequestSendOTP = false;
		}
	}

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'email',
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	$: {
		if ($data?.isUserLoggedIn) {
			if ($data.release?.emails?.length) navigateTo('/');
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading={$_('Requires your email')}
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<form class="max-w-md mx-auto" on:submit|preventDefault={() => verifyEmail(false)}>
		<div class="relative">
			<!-- svelte-ignore a11y-autofocus -->
			<input
				id="email"
				bind:value
				type="email"
				name="email"
				class="h-12 w-full px-4"
				required
				autofocus
				autocomplete="email"
				autocapitalize="off"
				placeholder={$_('enter your email')}
			/>

			{#if showOTPModal}
				<Modal position="center" on:close={() => (showOTPModal = false)}>
					<VerifyOTP
						bind:otp
						bind:ajaxRequestSendOTP
						bind:ajaxRequestResendOTP
						verifyCode={verifyEmailCode}
						on:resend={() => verifyEmail(true)}
					/>
				</Modal>
			{/if}
		</div>

		<div class="flex justify-between items-center my-2">
			<button
				data-test="send-verification-code-btn"
				type="submit"
				disabled={!regex.test(value) || ajaxRequest}
				class="disabled:opacity-50 transition btn-background w-full inline-flex items-center justify-center"
			>
				{#if ajaxRequest}
					<SpinnerIcon css="h-5 w-5 text-white" />
				{:else}
					{$_('Send verification code')}
				{/if}
			</button>
		</div>
	</form>

	<section class="space-y-2">
		{#each logins.filter((i) => i.claims.verified_email) as provider}
			<LoginProvider
				on:click={(e) => continueWithProvider(provider.slug, e.detail)}
				{provider}
				prefix="Get email from"
			/>
		{/each}
	</section>
</AuthorizeLayout>
