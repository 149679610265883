<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import { data, notification, showSpinner, isRemoteAuthClient } from '../../stores.js';
	import { onDestroy, onMount } from 'svelte';
	import { getConsent, deleteConsent, putName } from '../../utils/api-calls';
	import { logPlausibleEvent } from '../../utils/helper.js';
	import AuthorizeLayout from '../../lib/layout/AuthorizeLayout.svelte';
	import SpinnerIcon from '../../lib/icon/SpinnerIcon.svelte';

	let value = '';
	let addPreferredNameAjax = false;
	let authCancelledAtRemoteClient = false;
	let authCancelledAtInitClient = false;
	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isUserLoggedIn) return replaceRoute('/login');

		//we already got a nickname
		if ($data.release?.nicknames?.length) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				$notification = {
					text: 'Authorization was cancelled on the other device',
					type: 'error'
				};
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				console.log('keep-alive: ' + event.data);
			});
		}

		logPlausibleEvent({ u: '/wizard/preferred-name' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function addPreferredName() {
		try {
			addPreferredNameAjax = true;
			const { nicknames } = await putName('nickname', value, false);
			$data.release.nicknames = nicknames;
			navigateTo('/');
		} catch (err) {
			addPreferredNameAjax = false;
			console.error(err);
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				$notification = {
					text: 'Authorization was cancelled',
					type: 'error'
				};
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading={$_('Requires your preferred name')}
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<form class="max-w-md mx-auto mt-2" on:submit|preventDefault={addPreferredName}>
		<div class="relative">
			<!-- svelte-ignore a11y-autofocus -->
			<input
				bind:value
				type="text"
				id="nickname"
				name="nickname"
				class="h-12 w-full px-4"
				required
				autofocus
				autocomplete="nickname"
				placeholder={$_('enter what you want to be called')}
			/>
		</div>

		<div class="flex justify-between items-center mt-2">
			<button
				data-test="continue-btn"
				type="submit"
				disabled={value.length < 1 || addPreferredNameAjax}
				class="disabled:opacity-50 disabled:cursor-not-allowed transition btn-background w-full"
			>
				{#if addPreferredNameAjax}
					<SpinnerIcon css="h-5 w-5 mx-auto text-white" />
				{:else}
					{$_('Continue')}
				{/if}
			</button>
		</div>
	</form>
</AuthorizeLayout>
