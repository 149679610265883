<script>
	import { onMount } from 'svelte';
	import { _ } from 'svelte-i18n';

	export let app = {};
	export let isDeviceCodeFlow = false;

	let redirect_uri = null;

	onMount(() => {
		if (!isDeviceCodeFlow) {
			try {
				redirect_uri = new URL(app.redirect_uri);
			} catch (err) {
				console.error('Invalid Redirect URL', err);
			}
		}
	});
</script>

<section
	class="w-full max-w-col mt-1 stack:mt-0 stack:w-full unstack:w-1/2 px-0 unstack:px-indent stack:px-0 pb-4 stack:pb-[5vh] [@media(min-height:1216px)]:!pb-16 mx-auto unstack:mr-auto unstack:ml-0 stack:mx-auto"
>
	<div class="app-info-logo-container mx-auto flex items-center stack:justify-center">
		{#if (!app.image_uri || app.image_uri == 'undefined') && (!app.dark_image_uri || app.dark_image_uri == 'undefined')}
			<picture>
				<source
					srcset="https://cdn.hello.coop/images/fallback-app-icon-light.png"
					media="(prefers-color-scheme: dark)"
				/>
				<img
					src="https://cdn.hello.coop/images/fallback-app-icon.png"
					alt={app.name}
					class="max-w-logo max-h-logo block object-contain opacity-50 mx-auto"
				/>
			</picture>
		{:else}
			<picture>
				<source srcset={app.dark_image_uri || app.image_uri} media="(prefers-color-scheme: dark)" />
				<img
					src={app.image_uri}
					alt={app.name}
					class="w-full h-auto max-w-logo max-h-logo block object-contain object-left stack:mx-auto unstack:mx-0 stack:object-center unstack:object-left"
				/>
			</picture>
		{/if}
	</div>
	{#if isDeviceCodeFlow}
		<span
			data-test="app-info-url"
			class="block text-center unstack:text-left stack:text-center mt-6"
			>({$_('Initiated on device')})</span
		>
	{:else if redirect_uri}
		<span
			href={redirect_uri}
			data-test="app-info-url"
			class="block text-center unstack:text-left stack:text-center mt-6">{redirect_uri.host}</span
		>
	{/if}
</section>
