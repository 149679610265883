<script>
	import { _ } from 'svelte-i18n';
	import { data } from '../stores';
	import DesktopIcon from './icon/DesktopIcon.svelte';
	import parser from 'ua-parser-js';
	import AppleIcon from './icon/AppleIcon.svelte';
	import AndroidIcon from './icon/AndroidIcon.svelte';
	import LinuxIcon from './icon/LinuxIcon.svelte';
	import WindowsIcon from './icon/WindowsIcon.svelte';
	import MobileIcon from './icon/MobileIcon.svelte';
	import TabletIcon from './icon/TabletIcon.svelte';

	$: userAgent = parser($data?.remoteDevice?.userAgent);
	$: os = userAgent?.os?.name;
	$: type = userAgent?.device?.type;
	$: browser = userAgent?.browser?.name;
</script>

<div class="bg-[#ebebeb] dark:bg-[#252525]">
	<div class="px-3 py-4 flex flex-col justify-start max-w-md container mx-auto">
		<span class="text-sm">{$_('You are authorizing the following device')}</span>
		<div class="flex justify-between items-end">
			<div class="flex items-center mt-1">
				<span class="-mt-1 w-12 h-12 relative inline-flex items-center justify-center">
					{#if os.includes('Mac') || os.includes('iOS')}
						<span class="absolute">
							<AppleIcon />
						</span>
					{:else if os.includes('Android')}
						<span class="absolute">
							<AndroidIcon />
						</span>
					{:else if os.includes('Linux')}
						<span class="absolute">
							<LinuxIcon />
						</span>
					{:else if os.includes('Windows')}
						<span class="absolute" class:mb-2={type == undefined}>
							<WindowsIcon />
						</span>
					{/if}

					{#if type === 'mobile'}
						<MobileIcon />
					{:else if type === 'tablet'}
						<TabletIcon />
					{:else}
						<DesktopIcon />
					{/if}
				</span>
				<div class="flex flex-col mt-2 ml-4">
					<span>{browser} {os}</span>
					<span class="text-xs"
						>near {$data?.remoteDevice?.location?.City}, {$data?.remoteDevice?.location
							?.Country}</span
					>
				</div>
			</div>
			<button on:click class="text-sm hover:underline focus:underline"
				>{$_('This is not me')}</button
			>
		</div>
	</div>
</div>
